import React , {Component} from 'react';
import { CallClient} from "@azure/communication-calling";
import { AzureCommunicationTokenCredential } from "@azure/communication-common";
import { CommunicationIdentityClient } from "@azure/communication-identity";
import { ChatClient } from "@azure/communication-chat";
 
class Chat extends Component{
    


    componentDidMount(){
       // this.init();
    }

    call:any;
    callAgent:any;
    chatClient:any;
    chatThreadClient:any;
    
    meetingLinkInput = document.getElementById('teams-link-input')  as HTMLInputElement;
    threadIdInput = document.getElementById('thread-id-input') as HTMLInputElement;
    callButton = document.getElementById("join-meeting-button") as HTMLButtonElement;
    hangUpButton = document.getElementById("hang-up-button") as HTMLButtonElement;
    callStateElement = document.getElementById('call-state') as HTMLInputElement;
    
     messagesContainer = document.getElementById("messages-container") as HTMLElement;
     chatBox = document.getElementById("chat-box")  as HTMLElement;
     sendMessageButton = document.getElementById("send-message");
     messagebox = document.getElementById("message-box") as HTMLInputElement;

     userId:any;
     messages:any;


    async init() {
        const connectionString = "endpoint=https://virtual-visit-poc-cs.communication.azure.com/;accesskey=76xL4rAJRwTaK6XlebOrwt7pA7TkRtGNohMokzdBuWDSXND+7g5MGMiyH8jiez2SgnK5uoRjCF4IhDug5Ls3aw==";
        const endpointUrl = "https://virtual-visit-poc-cs.communication.azure.com";

        const identityClient = new CommunicationIdentityClient(connectionString);

        let identityResponse = await identityClient.createUser();
        this.userId = identityResponse.communicationUserId;
        console.log(`\nCreated an identity with ID: ${identityResponse.communicationUserId}`);

        /*let tokenResponse = await identityClient.getToken(identityResponse, [
            "voip",
            "chat",
        ]);*/

        let tokenResponse = await identityClient.getToken(identityResponse, [
            "chat",
        ]);

        const { token, expiresOn } = tokenResponse;
        console.log(`\nIssued an access token that expires at: ${expiresOn}`);
        console.log(token);

        const callClient = new CallClient();
        const tokenCredential = new AzureCommunicationTokenCredential(token);
        this.callAgent = await callClient.createCallAgent(tokenCredential);
       // this.callButton.disabled = false;

        this.chatClient = new ChatClient(
            endpointUrl,
            new AzureCommunicationTokenCredential(token)
        );

        console.log('Azure Communication Chat client created!');
} //end init

 
 
    

    handleCall = async () => {        
	    this.call = this.callAgent.join({meetingLink: this?.meetingLinkInput?.value}, {});
        this.call.on('stateChanged', () => {
            this.callStateElement.innerText = this.call.state;
        })
        // toggle button and chat box states
        this.chatBox.style.display = "block";
        this.hangUpButton.disabled = false;
	    this.callButton.disabled = true;
        this.messagesContainer.innerHTML = this.messages;
        await this.chatClient.startRealtimeNotifications();

        // subscribe to new message notifications
        this.chatClient.on("chatMessageReceived", (e:  any) => {
            console.log("Notification chatMessageReceived!");

        // check whether the notification is intended for the current thread
            if (this.threadIdInput.value != e.threadId) {
                return;
            }

            if (e.sender.communicationUserId != this.userId) {
                 this.renderReceivedMessage(e.message);
            }
            else {
                 this.renderSentMessage(e.message);
            }
	});

	this.chatThreadClient = await this.chatClient.getChatThreadClient(this.threadIdInput.value);
      
    }
 
    renderReceivedMessage = async (message:string) => { 
        this.messages += '<div class="chat-container lighter">' + message + '</div>';
        this.messagesContainer.innerHTML = this.messages;
    }   
     
    
    renderSentMessage = async (message:string) => { 
        this.messages += '<div class="chat-container darker">' + message + '</div>';
        this.messagesContainer.innerHTML = this.messages;
    }

    handleSendMessage = async () => { 
        let message = this.messagebox?.value;

		let sendMessageRequest = { content: message };
		let sendMessageOptions = { senderDisplayName : 'Jack' };
		let sendChatMessageResult = await this.chatThreadClient.sendMessage(sendMessageRequest, sendMessageOptions);
		let messageId = sendChatMessageResult.id;

		this.messagebox.value = '';
		console.log(`Message sent!, message id:${messageId}`);
    }


   

   
    handleHangeUp = async () => { 
        await this.call.hangUp();

		// toggle button states
		this.hangUpButton.disabled = true;
		this.callButton.disabled = false;
		this.callStateElement.innerText = '-';

		// toggle chat states
		this.chatBox.style.display = "none";
		this.messages = "";
    }
 
    render(){
        return (
            <div>
                <h4>Azure Communication Services</h4>
                <h1>Calling and Chat Quickstart</h1>
                    <input id="teams-link-input" type="text" placeholder="Teams meeting link"
                    style={{marginBottom:'1em', width: '300px'}} />
                    <input id="thread-id-input" type="text" placeholder="Chat thread id"
                    style={{marginBottom:'1em', width: '300px'}} />
                    <p>Call state <span style={{fontWeight: 'bold'}} id="call-state">-</span></p>
                <div>
                    <button id="join-meeting-button" type="button" onClick={this.handleCall}>
                        Join Teams Meeting
                    </button>
                    <button id="hang-up-button" type="button" onClick={this.handleHangeUp} >
                        Hang Up
                    </button>
                </div>
                <div className="chat-popup" id="chat-box">
                    <div id="messages-container"></div>
                    <form className="form-container">
                        <textarea placeholder="Type message.." name="msg" id="message-box" required></textarea>
                        <button type="button" className="btn" id="send-message" onClick={this.handleSendMessage}>Send</button>
                    </form>
                        </div>
            </div>
        )
    }
}
export default Chat;