import { connect } from 'react-redux';
import PublicConfigurationScreen, { PublicConfigurationScreenProps } from '../components/PublicConfiguration';
import { setGroup } from '../core/actions/calls';
import { setVideoDeviceInfo, setAudioDeviceInfo } from '../core/actions/devices';
import { initCallAgent, initCallClient, updateDevices } from '../core/sideEffects';
import { setMic } from '../core/actions/controls';
import { State } from '../core/reducers';
import { AudioDeviceInfo, VideoDeviceInfo, LocalVideoStream } from '@azure/communication-calling';
import { setLocalVideoStream } from '../core/actions/streams';
import { setMeetingID, setMeetingLink, setMeetingStartTime } from '../core/actions/common';

const mapStateToProps = (state: State, props: PublicConfigurationScreenProps) => ({
  deviceManager: state.devices.deviceManager,
  callAgent: state.calls.callAgent,
  group: state.calls.group,
  mic: state.controls.mic,
  screenWidth: props.screenWidth,
  localVideoStream: state.streams.localVideoStream,
  audioDeviceInfo: state.devices.audioDeviceInfo,
  videoDeviceInfo: state.devices.videoDeviceInfo,
  videoDeviceList: state.devices.videoDeviceList,
  audioDeviceList: state.devices.audioDeviceList,
  cameraPermission: state.devices.cameraPermission,
  microphonePermission: state.devices.microphonePermission,
  meetingID:state.common.meetingID,
  meetingLink:state.common.meetingLink,
  fullName:state.common.fullName,
  nin: state.common.nin,
  otpToken: state.common.token,
  meetingStartTime:state.common.meetingStartTime,
  mobile: state.common.mobile,
  serviceType: state.common.serviceType,
  subServiceType: state.common.subServiceType,
}) ;

const mapDispatchToProps = (dispatch: any, props: PublicConfigurationScreenProps) => ({
  setLocalVideoStream: (localVideoStream: LocalVideoStream) => dispatch(setLocalVideoStream(localVideoStream)),
  setMic: (mic: boolean) => dispatch(setMic(mic)),
  setAudioDeviceInfo: (deviceInfo: AudioDeviceInfo) => dispatch(setAudioDeviceInfo(deviceInfo)),
  setVideoDeviceInfo: (deviceInfo: VideoDeviceInfo) => dispatch(setVideoDeviceInfo(deviceInfo)),
  setupCallClient: (unsupportedStateHandler: () => void) =>
    dispatch(initCallClient( unsupportedStateHandler)),
  setupCallAgent: (displayName: string) =>
    dispatch(initCallAgent(displayName, props.callEndedHandler)),
  setGroup: (groupId: string) => dispatch(setGroup(groupId)),
  updateDevices: () => dispatch(updateDevices()),
  setMeetingID:(meetingID:string) => dispatch(setMeetingID(meetingID)),
  setMeetingLink:(meetingLink:string) => dispatch(setMeetingLink(meetingLink)),
  setMeetingStartTime:(meetingStartTime:string) => dispatch(setMeetingStartTime(meetingStartTime))
})

const connector: any = connect(mapStateToProps, mapDispatchToProps);
export default connector(PublicConfigurationScreen);

