import React, { useState, useEffect } from "react"
// import 'bootstrap/dist/css/bootstrap.css';
import appconfig from "./../appconfig.json";
import axios from 'axios';
import setLanguage from 'translation';
//import { store } from "App";

interface props {
    isOpen: any, onClose: any, data: any, urlAttributes: any
}


const NafathModal = ({ isOpen, onClose, data, urlAttributes }: props) => {

    const [loading, setLoading] = useState(false);

    const [waitingStatus, setWaitingStatus] = useState(false);

    const [approvedStatus, setApprovedStatus] = useState(false);

    const [rejectedStatus, setRejectedStatus] = useState(false);

    const [errorApis, setErrorApis] = useState(false);

    const [maxTriesNumber, setMaxTriesNumber] = useState(false)

    useEffect(() => {
        const language = localStorage.getItem("lang");
        setLanguage(language);

    }, []);

    //children
    if (!isOpen) return null;
    console.log(data)

    //    const checkStatus = async(event: React.SyntheticEvent<HTMLButtonElement>) =>{
    //         event.preventDefault();

    //         setLoading(true);  

    //         let api_url = `https://endak-nafath-service.azurewebsites.net/api/v1/endakNafath/sbc/checkRequestStatus`;

    //         // appconfig.azure_reports_url + "/api/sendcustomerotp";
    //         // const body = {
    //         //     "id": Number(nin),
    //         // };

    //         const options = {
    //             headers: {
    //                 "Content-Type": "application/json"
    //             }
    //         };
    //         let responseApi;

    //         await axios.post(api_url, data, options).then(response => {
    //             console.log("send otp response = " + response);
    //             responseApi = {...response};

    //             if(responseApi.data.status === "APPROVED"){
    //                 setWaitingStatus(false);
    //                 setApprovedStatus(true);
    //                 setRejectedtatus(true);
    //                 //onClose();
    //                // window.location.href = "?isPublic=true&config=true&fullname=" + urlAttributes.fullname + "&nin=" +urlAttributes.nin + "&mobile=" + urlAttributes.mobile + "&serviceType=" + urlAttributes.service + "&client=" + urlAttributes.client;
    //             }
    //             else if(responseApi.data.status === "WAITING"){
    //                 setWaitingStatus(true);
    //                 setApprovedStatus(false);
    //                 setRejectedtatus(false);
    //                 // setTimeout(function(){
    //                 //     onClose();
    //                 // },6000);
    //             }
    //             else if(responseApi.data.status === "REJECTED"){
    //                 setWaitingStatus(false);
    //                 setApprovedStatus(false);
    //                 setRejectedtatus(true);
    //                 // setTimeout(function(){
    //                 //     onClose();
    //                 // },1000);
    //             }




    //         }).catch(err => {
    //             //submitButtonSpinner.style.display = 'none';
    //             // submitbtn.disabled = false;
    //             console.log(err);
    //             responseApi = err;

    //             //alert('تعذر ارسال رمز التحقق، الرجاء المحاولة في وقت لاحق');
    //         });
    //         console.log("responseapi", responseApi);
    //         setLoading(false);
    //         return responseApi;
    //     }

    const checkStatus = async (event: React.SyntheticEvent<HTMLButtonElement>) => {
        event.preventDefault();
        setLoading(true);

        const api_url = appconfig.azure_nafath + `/api/v1/endakNafath/sbc/checkRequestStatus`;
        const options = {
            headers: {
                "Content-Type": "application/json"
            }
        };
        let responseApi: any;
        try {

            const maxRetries = 2; // Set the maximum number of retries



            for (let retry = 0; retry < maxRetries; retry++) {
                // Make the API request
                const response = await axios.post(api_url, data, options);
                responseApi = { ...response };

                if (responseApi.data.status === "APPROVED") {
                    setWaitingStatus(false);
                    setApprovedStatus(true);
                    setRejectedStatus(true);
                    setMaxTriesNumber(false);
                    console.log(approvedStatus);
                    onClose();
                    window.location.href = "?isPublic=true&config=true&fullname=" + urlAttributes.fullname + "&nin=" + urlAttributes.nin + "&mobile=" + urlAttributes.mobile + "&serviceType=" + urlAttributes.service + "&client=" + urlAttributes.client;
                    break; // Exit the loop if status is "APPROVED"
                } else if (responseApi.data.status === "WAITING") {
                    setWaitingStatus(true);
                    setApprovedStatus(false);
                    setRejectedStatus(false);
                    setMaxTriesNumber(false);
                    await new Promise(resolve => setTimeout(resolve, 3000));
                    // setTimeout(function(){
                    //     onClose();
                    // }, 6000);
                } else if (responseApi.data.status === "REJECTED") {
                    setWaitingStatus(false);
                    setApprovedStatus(false);
                    setRejectedStatus(true);
                    setMaxTriesNumber(false);
                    let showStatus = document.getElementById("rejectStatus") as HTMLElement;
                    showStatus.style.display = "";
                    // setTimeout(function(){
                    break;
                }
            }
        } catch (error) {
            let cnfbtn = document.getElementById("confirmBtn") as HTMLElement;
            cnfbtn.style.display = "none";
            setErrorApis(true);
            setLoading(false);
            // setTimeout(function(){
            //     onClose();
            //  }, 1500);
            console.error("Error fetching status:", error);

            console.error("responseApis:", responseApi);
            // Handle error (e.g., show an error message)
        }
        if (responseApi?.data.status === "WAITING") {
            setWaitingStatus(false);
            setMaxTriesNumber(true);

            setTimeout(function () {
                setLoading(false);
                onClose();
            }, 3000);
            console.log(`waiting screen setimeout`)
        }
        else if (responseApi?.data.status === "REJECTED") {
            setLoading(false);
            let vrfynum = document.getElementById("vrfyNumber") as HTMLElement;
            let cnfbtn = document.getElementById("confirmBtn") as HTMLElement;
            vrfynum.style.display = "none";
            cnfbtn.style.display = "none";
            console.log(`rejected screen setimeout`);
            let showStatus = document.getElementById("rejectStatus") as HTMLElement;
            showStatus.style.display = "";
            // setTimeout(function(){
            //     onClose();
            //  }, 1500);
        }
    };

    //    const fetchMockData = () => {
    //         return new Promise((resolve) => {
    //           // Simulate an API call delay (optional)
    //           setTimeout(() => {
    //             resolve({
    //                 data: {
    //                     "status":"REJECTED"
    //                     }

    //             });
    //           }, 1000); // Simulated delay of 1 second
    //         });
    //       }
    //INSIDE FOR LOOP FOR MOCKUP
    //    responseApi = await fetchMockData();

    //     if (responseApi.data.status === "APPROVED") {
    //         setWaitingStatus(false);
    //         setApprovedStatus(true);
    //         setRejectedStatus(true);
    //         onClose();
    //         //window.location.href = "?isPublic=true&config=true&fullname=" + urlAttributes.fullname + "&nin=" + urlAttributes.nin + "&mobile=" + urlAttributes.mobile + "&serviceType=" + urlAttributes.service + "&client=" + urlAttributes.client;
    //         break; // Exit the loop if status is "APPROVED"
    //     } else if (responseApi.data.status === "WAITING") {
    //         setWaitingStatus(true);
    //         setApprovedStatus(false);
    //         setRejectedStatus(false);
    //         console.log(`waiting screen ${retry}`)
    //         // setTimeout(function(){
    //         //     onClose();
    //         // }, 6000);
    //     } else if (responseApi.data.status === "REJECTED") {
    //         setWaitingStatus(false);
    //         setApprovedStatus(false);
    //         setRejectedStatus(true);
    //         // setTimeout(function(){
    //         break;
    //     }

    return (
        <div
            // onClick={onClose}
            style={{
                position: "fixed",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                background: "rgba(0, 0, 0, 0.5)",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
            }}
        >
            <div
                style={{
                    background: "white",
                    // height: 210,
                    // width: 325,
                    margin: "auto",
                    padding: "2%",

                    borderRadius: "10px",
                    boxShadow: "2px solid black",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center"
                }}
            >
                {/* <button  onClick={() => onClose() }>
                   <svg width="40" height="40" viewbox="0 0 40 40"><path d="M 10,10 L 30,30 M 30,10 L 10,30" stroke="black" stroke-width="4" /></svg>
                </button> */}
                {/* {children} */}
                <div className="container-popuop">
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                        }}
                    >
                        <div
                            style={{
                                width: "100%",
                                maxWidth: "100%",
                                textAlign: "center"
                            }}
                        >
                            <h6>
                                {!rejectedStatus && !waitingStatus && !errorApis && <span data-i18n="confirmationNafath">التاكيد عن طريق نفاذ </span>}

                                {/* {rejectedStatus && <span data-i18n="verificationRejected" id="rejectStatus" style={{display:"none"}}>انتهى الوقت. الرجاء المحاولة مرة أخرى</span> } */}
                                <span data-i18n="verificationRejected" id="rejectStatus" style={{ display: "none" }}>الرجاء المحاولة مرة أخرى</span>
                                {waitingStatus && <span data-i18n="underVerification">  قيد التحقق </span>}

                                {maxTriesNumber && <span data-i18n="maxTriesNumberMessg"> انتهى الوقت. الرجاء المحاولة مرة أخرى </span>}

                                {errorApis &&
                                    <span data-i18n="errorApi"> يوجد عطل برجاء المحاولة مرة أخرى </span>
                                }
                            </h6>
                            {!errorApis &&
                                <span id="vrfyNumber"
                                    style={{
                                        width: "40px",
                                        height: "40px",
                                        border: "1px solid #2B2969",
                                        borderRadius: "5px",
                                        padding: "12px",
                                        fontSize: "16px",
                                        display: "block",
                                        margin: "auto"
                                        
                                    }}
                                    lang="en"
                                >
                                    {data.random}
                                </span>
                            }
                            {!rejectedStatus && !waitingStatus && !errorApis &&
                                <span style={{

                                    fontSize: "12px"
                                }}
                                    data-i18n="openNafath"
                                > الرجاء فتح تطبيق نفاذ وتأكيد الرقم أعلاه </span>
                            }
                            {waitingStatus && <span data-i18n="updateVerification">  يمكنك تحديث التحقق من خلال التأكيد مرة اخري</span>}
                        </div>

                    </div>

                    <div
                        style={{
                            width: "100%",
                            maxWidth: "100%",
                            marginTop: "10px"
                        }}
                    >
                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                            }}
                        >
                            <button id="confirmBtn" style={{ backgroundColor: "#2B2969", margin: "0px 6px" }} className="btn btn-primary" onClick={(SyntheticEvent) => checkStatus(SyntheticEvent)} disabled={loading}>
                                <span data-i18n="confirmInvestigation"> تحقق</span>
                                {loading && <span id="submitButtonSpinner" className="spinner-border spinner-border-sm"></span>}
                            </button>

                            {/* <button style={{backgroundColor: "#2B2969"}} className="btn btn-primary" onClick={() => onClose() }>
                                <span data-i18n="closeBtn">إلغاء </span>  
                            </button> */}
                        </div>
                    </div>

                </div>



            </div>
        </div>
    );
};

export default NafathModal;