import React , {Component} from 'react'
import 'bootstrap/dist/css/bootstrap.css';
class UnSupportedBrowserScreen extends Component{
    /*openCallMe = () =>{
        var url = "http://www.google.com/";
        window.location.href = url;
    }
    
    openContactUs = () =>{
        var url = "http://www.google.com/";
        window.location.href = url;
    }*/
    
    render(){
        return (
            <div id="main">
                <div className='main-container'>
                <div className="row">
                         <div className="col-md-12">
                         <h2>
                         خدمة الزيارة الافتراضية
                            <span className="icon">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-headset" viewBox="0 0 16 16">
  <path d="M8 1a5 5 0 0 0-5 5v1h1a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V6a6 6 0 1 1 12 0v6a2.5 2.5 0 0 1-2.5 2.5H9.366a1 1 0 0 1-.866.5h-1a1 1 0 1 1 0-2h1a1 1 0 0 1 .866.5H11.5A1.5 1.5 0 0 0 13 12h-1a1 1 0 0 1-1-1V8a1 1 0 0 1 1-1h1V6a5 5 0 0 0-5-5z"></path>
</svg>
                            </span>
                            
                            </h2>
                                                  </div>
                    </div>
                    <div className='row'>
                        <div className="col-md-12" style={{textAlign:"right"}} dir="rtl">
                        
                                                    
                                عزيزي العميل،
                                <br></br><br></br>
                                عذراً  المتصفح المستخدم غير مدعوم ، نأمل تغيير المتصفح واستخدام Google Chrome  
                                <br></br><br></br>
                                شكراً لتواصلك معنا عبر خدمة الزيارة الافتراضية


                              
                        </div>
                    </div>
                    
                    
                </div>
            </div>
        )
    }
}

export default UnSupportedBrowserScreen;