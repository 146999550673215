

const SET_USERID = 'SET_USERID';
const SET_DISPLAY_NAME = 'SET_DISPLAY_NAME';
const SET_USER_TOKEN = 'SET_USER_TOKEN';
const SET_FULL_NAME = 'SET_FULL_NAME';
interface SetUserIdAction {
  type: typeof SET_USERID;
  userId: string;
}

interface SetDisplayNameAction {
  type: typeof SET_DISPLAY_NAME;
  displayName: string;
}

interface SetUserTokenAction{
  type: typeof SET_USER_TOKEN;
  userToken: string;
}


interface SetFullNameAction{
  type: typeof SET_FULL_NAME;
  fullName: string;
}


export const setUserId = (userId: string): SetUserIdAction => {
  return {
    type: SET_USERID,
    userId
  };
};

export const setUserToken = (userToken: string): SetUserTokenAction => {
  return {
    type: SET_USER_TOKEN,
    userToken
  };
};

export const setDisplayName = (displayName: string): SetDisplayNameAction => {
  return {
    type: SET_DISPLAY_NAME,
    displayName
  };
};


export const setFullName = (fullName: string): SetFullNameAction => {
  return {
    type: SET_FULL_NAME,
    fullName
  };
};

export { SET_USERID, SET_USER_TOKEN,  SET_DISPLAY_NAME ,  SET_FULL_NAME };

export type SdkTypes = SetUserIdAction | SetDisplayNameAction | SetUserTokenAction | SetFullNameAction;
