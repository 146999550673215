import React, { useEffect, useState } from 'react';
import { translations } from 'translation';
import { ReactComponent as NafathLogoSvg } from '../assets/NafathLogo.svg';
import axios from 'axios';
import appconfig from './../appconfig.json';
import {ReactComponent as DangerIcon } from '../assets/danger-icon.svg'

type Props = {
  showNafathBlock: boolean;
  setInvestorData: (data: { name: string; nin: number; investor: any }) => void;
};

const Nafath = ({ showNafathBlock, setInvestorData }: Props) => {
  const [ninErrorMessage, setNinErrorMessage] = useState('');
  const [nin, setNin] = useState('');
  const [nafathErrorApi, setNafathErrorApi] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRejected, setIsRejected] = useState(false);
  const [nafathObject, setNafathObject] = useState({});
  const [nafathErrorMultipleCallingApi, setNafathErrorMultipleCallingApi] = useState(false);
  const [retryCountdown, setRetryCountdown] = useState(0);

  useEffect(() => {
    return () => {
      setIsRejected(false);
      setNafathObject({});
    };
  }, [showNafathBlock]);

  useEffect(() => {
    let timer: NodeJS.Timeout | undefined;
    if (retryCountdown > 0) {
      timer = setInterval(() => {
        setRetryCountdown((prevCountdown) => prevCountdown - 1);
      }, 1000);
    } else if (retryCountdown === 0) {
      clearInterval(timer);
    }
    return () => clearInterval(timer);
  }, [retryCountdown]);

  const checkNinOnlyDigits = (event: any) => {
    const languageSelector = document.getElementById('languageSelector') as HTMLInputElement;
    let language = languageSelector.value;

    const re = /^[0-9\b]+$/;
    //const re = /^[\u0660-\u0669\u06F0-\u06F90-9]/g;
    if (event.target.value === '' || re.test(event.target.value)) {
      setNin(event.target.value);
      setNinErrorMessage('');
    } else {
      setNinErrorMessage(translations[language]['validation_only_digits']); //" غير مسموح باستخدام الحروف";
    }
  };

  const callNafath = async (nin: any) => {
    let api_url = appconfig.azure_nafath + `/api/v1/endakNafath/sbc/login`;

    // appconfig.azure_reports_url + "/api/sendcustomerotp";
    const body = {
      id: Number(nin)
    };

    const options = {
      headers: {
        'Content-Type': 'application/json'
      }
    };
    let responseApi;

    await axios
      .post(api_url, body, options)
      .then((response) => {
        console.log('send otp response = ' + response);
        responseApi = { ...response };
      })
      .catch((err) => {
        //submitButtonSpinner.style.display = 'none';
        // submitbtn.disabled = false;
        console.log(err);
        responseApi = err;

        //alert('تعذر ارسال رمز التحقق، الرجاء المحاولة في وقت لاحق');
      });
    console.log('responseapi', responseApi);
    return responseApi;
  };

  const fetchRequest = async (data: any) => {
    const api_url = appconfig.azure_nafath + `/api/v1/endakNafath/sbc/checkRequestStatus`;
    const options = {
      headers: {
        'Content-Type': 'application/json'
      }
    };
    return await axios.post(api_url, data, options);
  };

  const submitToNafath = async () => {
    if ((nafathObject as any)?.random) {
      setIsRejected(false);
      setNafathErrorApi(false);
      setNafathObject({});
      return;
    }
    const languageSelector = document.getElementById('languageSelector') as HTMLInputElement;
    let language = languageSelector.value;

    if (nin.length != 10) {
      setNinErrorMessage(translations[language]['digit10Msg']);
      return;
    }
    setIsLoading(true);
    setNafathErrorApi(false);
    let response: any = await callNafath(nin);
    // let response: any = await new Promise((resolve) => {
    //   setTimeout(() => {
    //     resolve({ status: 200, data: { random: 20 } });
    //   }, 2000);
    // });
    if (response && response.status === 200) {
      if (response?.data?.random) {
        setNafathErrorApi(false);
        setNafathObject({ ...response.data, id: Number(nin) });
        setIsLoading(false);
        // Start the countdown for retry
        setRetryCountdown(60);

        const intervalId = setInterval(async () => {
          try {
            console.log('DAMN every 2 seconds');
            const res = await fetchRequest({ ...response.data, id: Number(nin) });
            // let res: any = await new Promise((resolve) => {
            //   setTimeout(() => {
            //     resolve({
            //       data: {
            //         status: 'REJECTED',
            //         person: { arabicName: 'سليمن', dob: 3, gender: 'male', nationality: 'Egyptian', language: 'Arabic' }
            //       }
            //     });
            //   }, 3000);
            // });
            if (res.data.status === 'APPROVED') {
              setInvestorData({
                investor: JSON.stringify(res.data.person),
                name: res.data.person.arabicName,
                nin: Number(nin)
              });
              clearInterval(intervalId);
            }
            if (res.data.status === 'REJECTED') {
              setIsRejected(true);
              clearInterval(intervalId);
            }
          } catch (error) {
            setNafathErrorApi(true);
            clearInterval(intervalId);
          }
        }, 2000);
        // try {
        //   //const res = await fetchRequest({ ...response.data, id: Number(nin) })
        //   let res: any = await new Promise((resolve) => {
        //     setTimeout(() => {
        //       resolve({ data: { status: 'APPROVED', person: { arabicName: 'سليمن' } } });
        //     }, 3000);
        //   });
        //   if (res.data.status === 'APPROVED') {
        //     setInvestorData({ name: res.data.person.arabicName, nin: Number(nin) });
        //   }
        //   if (res.data.status === 'REJECTED') {
        //     setIsRejected(true);
        //   }
        // } catch (error) {
        //   setNafathErrorApi(true);
        // }
      } else {
        setIsLoading(false);
        setNafathErrorMultipleCallingApi(true);
        setTimeout(() => {
          setNafathErrorMultipleCallingApi(false);
        }, 6000);
      }
    } else {
      setIsLoading(false);
      setNafathErrorApi(true);
    }
  };

  const isRetryStatus = retryCountdown > 0 && !isRejected

  return (
    <>
      {/* Start New Nafath WorkFlow  */}
      <div style={{ display: showNafathBlock ? 'block' : 'none' }}>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '30px' }}>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <h2 data-i18n="sign_in_with_nafath" style={{ color: '#195F00', fontSize: '19px', fontWeight: '400' }}>
              الدخول عبر النفاذ الوطني الموحد
            </h2>
            {/* start logo */}
            <NafathLogoSvg />
            {/* end logo */}
          </div>

          <div
            style={{
              display: (nafathObject as any)?.random ? 'flex' : 'none',
              flexDirection: 'column',
              gap: '30px',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <h2
              data-i18n="please_try_again"
              style={{ display: isRejected ? '' : 'none', color: '#195F00', fontSize: '19px', fontWeight: '400' }}
            >
              يرجى المحاولة مرة أخرى{' '}
            </h2>

            <>
              <div
                style={{
                  display: isRejected ? 'none' : 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: '76px',
                  height: '75px',
                  border: '1px solid #662577',
                  borderRadius: '5px',
                  fontSize: '22px'
                }}
                className="english-number"
              >
                {(nafathObject as any).random}
              </div>
              <h2
                data-i18n="please_open_nafath_and_confirm"
                style={{ color: '#195F00', fontSize: '19px', fontWeight: '400',display: isRejected ? 'none' : '', }}
              >
                الرجاء فتح تطبيق نفاذ وتأكيد الطلب بأختيار الرقم أعلاه
              </h2>
            </>
          </div>

          <div style={{ display: (nafathObject as any)?.random ? 'none' : '' }}>
            <div className="row">
              <span style={{ color: '#C8CADC', fontSize: '14px', fontWeight: '600' }} data-i18n="nin">
                {' '}
                الهوية الوطنية
              </span>
            </div>
            <div className="row">
              <input
                className={`secondary-input override-placeholder`}
                maxLength={10}
                type="text"
                data-i18n="nin_placeholder"
                onChange={checkNinOnlyDigits}
                value={nin}
                placeholder="الهوية الوطنية / الاقامة"
                name="nin"
              />
            </div>
            <div className="row">
              <span
                style={{ color: 'red', display: ninErrorMessage ? 'block' : 'none', fontSize: '11px' }}
                //data-i18n="required_field"
              >
                {ninErrorMessage}{' '}
              </span>
            </div>
          </div>

          <div className="row" style={{ alignSelf: 'end', width: isRetryStatus && (nafathObject as any)?.random ? '100%' : 'auto' }}>
            {isRetryStatus  && (nafathObject as any)?.random &&  (
              <span
                style={{ marginInlineEnd: 'auto',marginInlineStart:"-15px", color: '#8285A1', fontSize: '14px', alignContent: 'end' }}
                data-i18n={'dont_refresh_text'}
              >
                <DangerIcon /> الرجاء عدم تحديث الصفحة 
              </span>
            )}
            <button
              className="btn btn-primary"
              style={{
                backgroundColor: isRetryStatus  && (nafathObject as any)?.random ? '#8285A1 !important' : '#007bff',
                color: isRetryStatus  && (nafathObject as any)?.random ? '#C8CADC' : 'white',
                cursor: isRetryStatus  && (nafathObject as any)?.random ? 'not-allowed' : 'pointer'
              }}
              onClick={submitToNafath}
              disabled={isRetryStatus && (nafathObject as any)?.random}
            >
              <span
                style={{ display: (nafathObject as any)?.random && !isRetryStatus ? '' : 'none' }}
                data-i18n={'retry_btn'}
              >
                إعادة المحاولة{' '}
              </span>
              <span
                style={{ display: (nafathObject as any)?.random && isRetryStatus ? '' : 'none',wordSpacing:"3px" }}
                data-i18n={'retry_btn_after'}
              >
                إعادة المحاولة بعد &nbsp;
              </span>
              <span
                style={{
                  display: (nafathObject as any)?.random && isRetryStatus ? '' : 'none',
                  fontWeight: 'bold',
                  color: '#FFF'
                }}
                className='english-number'
              >
                &nbsp; {isRetryStatus && ` ${retryCountdown} `}
              </span>
              <span style={{ display: (nafathObject as any)?.random ? 'none' : '' }} data-i18n="submit_btn">
                استمرار
              </span>
              &nbsp;&nbsp;
              <span style={{ display: isLoading ? '' : 'none' }} className="spinner-border spinner-border-sm"></span>
            </button>
          </div>

          <span
            className="label1"
            data-i18n="nafathErrorApiMessg"
            style={{ display: nafathErrorApi ? '' : 'none', color: 'red', width: '100%' }}
          >
            يوجد خطأ يرجى طلب المساعدة
          </span>

          <span
            data-i18n="please_try_again"
            className="label1"
            style={{
              display: nafathErrorMultipleCallingApi ? '' : 'none',
              color: 'red',
              width: '100%',
              marginTop: '10px'
            }}
          >
            يرجى المحاولة مرة أخرى
          </span>
        </div>
      </div>
      {/* End New Nafath Work Flow  */}
    </>
  );
};

export default Nafath;
