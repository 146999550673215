import React , { Component } from "react"
import 'bootstrap/dist/css/bootstrap.css';
import axios from 'axios'; 
import appconfig from "./../appconfig.json";
import '../welcome.css';
 
class WelcomdeScreen extends Component{      
    loadDevices = function(){
    navigator.mediaDevices.enumerateDevices().then(function(devices) {
        devices.forEach(function(device) {
           // console.log(device.kind + ": " + device.label +" id = " + device.deviceId);
            const deviceType = device.kind;
            if(device.deviceId == "default" || device.deviceId == "communications") return;
            if(deviceType == 'audioinput'){                
                const  option = document.createElement("option");
                option.text = device.label;
                option.value = device.deviceId;
                document.getElementById('micList')?.appendChild(option);
            }else if(deviceType == 'audiooutput'){
                const  option = document.createElement("option");
                option.text = device.label;
                option.value = device.deviceId;
                document.getElementById('speakerList')?.appendChild(option);
            }
            else if(deviceType == 'videoinput'){
                const  option = document.createElement("option");
                option.text = device.label;
                option.value = device.deviceId;
                document.getElementById('cameraList')?.appendChild(option);
            }
        });
        })

        
    }
 
     
    componentDidMount(){   
        this.loadDevices()
        this.checkPermissions()
        this.readURLParameters()
    }

    checkPermissions = () =>{
            navigator.permissions.query({name: 'camera'})
            .then((permission) => {
                console.log("camera state", permission.state)
                if(permission.state != "granted"){  
                    const ele =  document.getElementById('cameraState')
                if(ele){
                    ele.style.display = '';
                }
                }else{
                    const ele =  document.getElementById('cameraList')
                    if(ele){
                    ele.style.display = '';
                    }
                }
            }) ; 

            navigator.permissions.query({name: 'microphone'})
            .then((permission) => {
                console.log("microphone state", permission.state);
                if(permission.state != "granted"){            
                    const ele =  document.getElementById('microphoneState');
                    if(ele){
                    ele.style.display = '';
                    }
                }else {
                    const ele =  document.getElementById('micList');
                    const eleSpeaker =  document.getElementById('speakerListDiv');
                    
                    if(ele){
                    ele.style.display = '';
                    }

                    if(eleSpeaker){
                        eleSpeaker.style.display = '';
                    }
                }
            }) ;
    }
   
    requestPermission =  (media: string) =>{   
          
        if(media == "microphone"){
 
            navigator.mediaDevices.getUserMedia({audio: true, video: false})
            .then(function(stream) {
                window.location.reload();
            });
        }
        if(media == "camera"){
        
            navigator.mediaDevices.getUserMedia({audio: false, video: true})
            .then(function(stream) {
                window.location.reload();
            });
        }
        this.loadDevices();
    }

    
    readURLParameters = () => {
        const queryParams = new URLSearchParams(window.location.search)
        const fullName = queryParams.get("fullname")
       // const nin = queryParams.get("nin")
        //const phone = queryParams.get("phone")  
        const type = queryParams.get("type")  ;
        const registrationNo = queryParams.get("registrationNo")  ;
        

        this.setState({
            fullName: fullName,
            //phone: nin,
            //nationalID: phone,
            type: type,
            registrationNo:registrationNo
        })
    }
 

    state = {
        fullName: '',
        //phone: '',
       // nationalID: '',
        registrationNo:'',
        type:''
      }
 
      openConfigurations(){
        let mic = document.getElementById('micList') as HTMLSelectElement;
        if(mic.value == "" ){
            alert("حتى نتمكن من خدمتك الرجاء تفعيل الكاميرا والميكروفون");
        }else {
           // const queryParams = new URLSearchParams(window.location.search)
           //let fullName = queryParams.get("fullname");
            //let isPublic = queryParams.get("isPublic");
            let url = window.location.href.replace("welcomeScreen","");
            //window.location.href = url+"?isPublic="+isPublic+"&config=true&fullname="+fullName;
            window.location.href = url+"&config=true";
        }
      }
      getMeetinglink = () =>{

        let mic = document.getElementById('micList') as HTMLSelectElement;
       // let camera = document.getElementById('cameraList') as HTMLSelectElement;
        if(mic.value == "" ){
            alert("حتى نتمكن من خدمتك الرجاء تفعيل الكاميرا والميكروفون");
        }else {
            const requestBody = {
                "fullName": this.state.fullName,
                //"mobileNumber":this.state.phone,
            // "nin":this.state.nationalID
            };
            //alert("registrationNo = "+this.state.registrationNo);
            //alert("Type = "+this.state.type);
            const url = appconfig.azure_url+"/api/customer-meeting"; 
            axios.post(url , requestBody).then(response =>{ 
               //window.location.href = response.data.meetingLink+"&meetingID="+response.data.id+"&fullname="+this.state.fullName;            
               let xurl = "http://localhost:3000/?meetingLink=https://teams.microsoft.com/l/meetup-join/19%3ameeting_YzM2Yzc1YWYtMzQ4MS00OGI5LWExN2UtOTYxOWQ4M2I2Nzgw%40thread.v2/0?context=%7b%22Tid%22%3a%221252a2de-b6fc-43d5-a090-215472ae9b25%22%2c%22Oid%22%3a%22949ce340-3529-4aa2-b83f-66eaef624d5a%22%7d&meetingID=62b4e06a-1a19-45d9-9a94-83b70b4085d3&fullname=Mahmoud";
               window.location.href = xurl;

            })
        }
      }
   
      
      
      
    render(){
        const queryParams = new URLSearchParams(window.location.search)
      const isPublic = queryParams.get("isPublic");
        if(isPublic != "true"){
        return(
            <div id="main">
                <div className="private-container">
                    <div className="row">
                         <div className="col-md-12" style={{textAlign:"right"}}>
                         <h2>
                         خدمة الزيارة الافتراضية
                            <span className="icon">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-headset" viewBox="0 0 16 16">
  <path d="M8 1a5 5 0 0 0-5 5v1h1a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V6a6 6 0 1 1 12 0v6a2.5 2.5 0 0 1-2.5 2.5H9.366a1 1 0 0 1-.866.5h-1a1 1 0 1 1 0-2h1a1 1 0 0 1 .866.5H11.5A1.5 1.5 0 0 0 13 12h-1a1 1 0 0 1-1-1V8a1 1 0 0 1 1-1h1V6a5 5 0 0 0-5-5z"></path>
</svg>
                            </span>
                            
                            </h2>
                                                   </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                        <div style={{textAlign:"right"}}>
                            تتيح هذه الخدمة إمكانية عقد زيارة افتراضية والتواصل مع موظف خدمة العملاء عبر الاتصال المرئي لتنفيذ المطلوب بكل سهولة ويسر
                                </div> 
                        </div>
                    </div>
                    <hr></hr>
                    <div className="row">
                        <div className="col-md-12"><h2>حتى نتمكن من خدمتك الرجاء تفعيل الكاميرا والميكروفون</h2></div>
                    </div>
                    <div className="row" id="speakerListDiv" style={{display:"none"}}>
                        <div className="col-md-6"></div>
                        <div className="col-md-6  startupList"  style={{textAlign:"right"}}  dir="rtl">
                               <span>جهاز الصوت</span>
                                <select  id="speakerList" className="select-form"></select>
                        </div>
                    </div>

                    <div className="row" >
                        <div className="col-md-6"></div>
                        <div className="col-md-6  startupList"  style={{textAlign:"right"}}  dir="rtl">
                        <span>ميكروفون</span>
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        <select style={{display:"none"}} id="micList" className="select-form"></select>
                        {/*<button className="btn btn-block btn-success" onClick={event => this.requestPermission('microphone')} id="microphoneState" style={{display:'none'}}>تفعيل</button>
                         */}
                         <br></br>
                        <label  id="microphoneState" style={{display:'none'}} className="switch"><input type="checkbox" onClick={event => this.requestPermission('microphone')} /><span className="slider round"></span></label>                            
                        </div>
                    </div>

                    <div className="row" >
                        <div className="col-md-6"></div>
                        <div className="col-md-6  startupList"  style={{textAlign:"right"}} dir="rtl">
                            <span>الكاميرا</span>
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            <select style={{display:"none"}} id="cameraList" className="select-form"></select>
                            {/*
                            <button className="btn btn-block btn-success" onClick={event => this.requestPermission('camera')} id="cameraState" style={{display:'none'}}>تفعيل</button>
                            */}
                            <br></br>
                            <label  id="cameraState" style={{display:'none'}} className="switch"><input type="checkbox" onClick={event => this.requestPermission('camera')} /><span className="slider round"></span></label>                            
                        </div>
                    </div>


                    
                    <div className="row">
                        <div className="col-md-3"></div>
                        <div className="col-lg-4 text-right" >
                            <div className="btn btn-success" onClick={this.openConfigurations} >استمرار</div>
                        </div>
                    </div>
                </div>
                 
                

                
            </div>
        );
       }  else {
        return (
            <div className="content-container">  
            <div className='row' dir="rtl">
                
            <div className='col-md-6 hide-sm'>
                        <div className='row'>
                            <div className='t2'>فرعنا الالكترونى <span className='t1'>عندك</span></div>
                        </div>
                        <div className='row'>
                            <span className='p1 p2'>تتيح هذه الخدمة امكانية عقد <b>زيارة افتراضية </b>والتواصل مع موظف خدمة العملاء عبر الاتصال المرئى بكل سهولة ويسر</span>
                        </div>
                    </div>

                <div className='col-md-6'>
                    <div className='leftSide'>
                    <div className='row'>
                            <div className='t1'>لخدمتك بشكل أفضل  </div>                                                      
                    </div>
                    <br></br>
                    <div className="row">
                            <span className='p1'> يرجى تفعيل الكامير والميكروفون</span>
                    </div>
                    <br></br>
                    <div className="row" id="speakerListRow_01"  style={{display:"none"}}><span>جهاز الصوت</span></div>
                    <div className="row" id="speakerListRow_02"  style={{display:"none"}}>
                        
                        <select id="speakerList" className="select-form"></select>
                        
                    </div>
                    <br></br>
                    <div className="row"  ><span>ميكروفون</span></div>
                    <div className="row" >
                        
                        <select style={{display:"none"}} id="micList" className="select-form"></select>
                        <button className="btn btn-block btn-success" onClick={event => this.requestPermission('microphone')} id="microphoneState" style={{display:'none'}}>تفعيل</button>
                    </div>
                    <br></br>
                    <div className="row" ><span>الكاميرا</span></div>
                    <div className="row" >
                        
                        <select style={{display:"none"}} id="cameraList" className="select-form"></select>
                        <button className="btn btn-block btn-success" onClick={event => this.requestPermission('camera')} id="cameraState" style={{display:'none'}}>تفعيل</button>
                    </div>

                    <br></br>

                    <div className="row"  >
                        <div className="btn btn-success btn1" onClick={this.openConfigurations} >استمرار</div>
                    </div>
                    </div>
                </div>

                
            </div>
        </div>
        );
       }        
    }  //render
 
}

export default WelcomdeScreen