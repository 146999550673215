// © Microsoft Corporation. All rights reserved.
/* eslint-disable react/display-name */
import React, { useState, useEffect } from 'react';
import { Label, Overlay, Stack } from '@fluentui/react';
import Header from '../containers/Header';
import MediaGallery from '../containers/MediaGallery';
import MediaFullScreen from './MediaFullScreen';
import CommandPanel, { CommandPanelTypes } from './CommandPanel';
import { Constants } from '../core/constants';
import WaitingScreen from './WaitingScreen';
//import { ChatClient} from "@azure/communication-chat";
//import { AzureCommunicationTokenCredential } from "@azure/communication-common";
//import { utils } from '../Utils/Utils';
import 'bootstrap/dist/css/bootstrap.css';
import appconfig from '../appconfig.json';
import axios from 'axios';
import { store } from 'App';
import {
  headerStyles,
  containerStyles,
  paneStyles,
  hiddenContainerClassName,
  activeContainerClassName,
  loadingStyle,
  overlayStyles
} from './styles/GroupCall.styles';
import {
  Call,
  LocalVideoStream,
  AudioDeviceInfo,
  VideoDeviceInfo,
  RemoteParticipant,
  CallAgent,
  DeviceManager,
  CallEndReason
} from '@azure/communication-calling';
import { ParticipantStream } from 'core/reducers/index.js';
import { ChatClient, ChatThreadClient } from '@azure/communication-chat';
import setLanguage, { translations } from 'translation';
//import CloseCaptions from 'containers/CloseCaptions';

export interface GroupCallProps {
  userId: string;
  displayName: string;
  userToken: string;
  groupId: string;
  call: Call;
  callAgent: CallAgent;
  chatClient: ChatClient;
  deviceManager: DeviceManager;
  mic: boolean;
  remoteParticipants: RemoteParticipant[];
  callState: string;
  localVideo: boolean;
  localVideoStream: LocalVideoStream;
  screenShareStreams: ParticipantStream[];
  audioDeviceInfo: AudioDeviceInfo;
  videoDeviceInfo: VideoDeviceInfo;
  audioDeviceList: AudioDeviceInfo[];
  videoDeviceList: VideoDeviceInfo[];
  screenWidth: number;
  shareScreen: boolean;
  setAudioDeviceInfo(deviceInfo: AudioDeviceInfo): void;
  setVideoDeviceInfo(deviceInfo: VideoDeviceInfo): void;
  setLocalVideoStream(stream: LocalVideoStream | undefined): void;
  mute(): void;
  enableMic(): void;
  isGroup(): void;
  joinGroup(): void;
  endCallHandler(): void;

  setupCallClient(unsupportedStateHandler: () => void): void;
  setupCallAgent(displayName: string): void;
  setGroup(groupId: string): void;
  startCallHandler(): void;
  unsupportedStateHandler: () => void;
  callEndedHandler: (reason: CallEndReason) => void;
  setMic(mic: boolean): void;
  receivedChatMessage: string;
  serverCallId: string;
  otpToken: string;
}

let allMessages: string;
let localProps: GroupCallProps;
export default (props: GroupCallProps): JSX.Element => {
  localProps = props;
  const [selectedPane, setSelectedPane] = useState(CommandPanelTypes.None);
  //const [receivedChatMessage, setReceivedChatMessage] = useState(props.receivedChatMessage);
  const activeScreenShare = props.screenShareStreams && props.screenShareStreams.length === 1;

  const { callAgent, call, setGroup, joinGroup, setupCallAgent, setupCallClient, unsupportedStateHandler } = props;

  const queryParams = new URLSearchParams(window.location.search);
  //const meetingLink = queryParams.get("meetingLink");
  const meetingLink = props.groupId;
  const fullname = queryParams.get('fullname');

  useEffect(() => {
    setupCallClient(unsupportedStateHandler);
    setupCallAgent(fullname == null ? '' : fullname);
  }, []);

  useEffect(() => {
    props.setMic(true);
    setGroup(meetingLink == null ? '' : meetingLink);
  }, [setupCallClient, unsupportedStateHandler]);

  useEffect(() => {
    if (callAgent && !call) {
      joinGroup();
    }
  }, [callAgent, call, joinGroup]);
  if (props.serverCallId) {
    //alert("Server Call ID = "+props.serverCallId);
  }

  return (
    <Stack horizontalAlign="center" verticalAlign="center" styles={containerStyles}>
      <Stack.Item styles={headerStyles}>
        <Header
          selectedPane={selectedPane}
          setSelectedPane={setSelectedPane}
          endCallHandler={() => {
            props.endCallHandler();
          }}
          screenWidth={props.screenWidth}
        />
      </Stack.Item>
      <Stack.Item styles={containerStyles}>
        {!props.shareScreen ? (
          props.callState === Constants.CONNECTED ? (
            <>
              <Stack horizontal styles={containerStyles}>
                <Stack.Item grow styles={activeScreenShare ? activeContainerClassName : hiddenContainerClassName}>
                  {activeScreenShare && <MediaFullScreen activeScreenShareStream={props.screenShareStreams[0]} />}
                </Stack.Item>

                {!activeScreenShare && (
                  <>
                    
                    <Stack.Item grow styles={!activeScreenShare ? activeContainerClassName : hiddenContainerClassName}>
                      <MediaGallery></MediaGallery>
                      {/* {CloseCaptions(call)} */}
                    </Stack.Item>
                  </>
                )}
                {selectedPane !== CommandPanelTypes.None &&
                  (window.innerWidth > Constants.MINI_HEADER_WINDOW_WIDTH ? (
                    <Stack.Item disableShrink styles={paneStyles}>
                      <CommandPanel {...props} selectedPane={selectedPane} setSelectedPane={setSelectedPane} />
                    </Stack.Item>
                  ) : (
                    <Overlay styles={overlayStyles}>
                      <CommandPanel {...props} selectedPane={selectedPane} setSelectedPane={setSelectedPane} />
                    </Overlay>
                  ))}
              </Stack>
            </>
          ) : (
            <div>
              {/*<Label>you are waiting in the queue</Label>  */}

              <WaitingScreen></WaitingScreen>
            </div>
          )
        ) : (
          <div className={loadingStyle}>
            <Label>Your screen is being shared</Label>
          </div>
        )}
      </Stack.Item>
    </Stack>
  );
};

export interface ChatProps {
  fileSelected: string;
  isFilePicked: boolean;
}
export class ChatFeature extends React.Component {
  chatThreadClient: ChatThreadClient | undefined;
  chatAgentData: any;
  componentDidMount() {
    const languageSelector = document.getElementById('languageSelector') as HTMLInputElement;
    let language1 = languageSelector.value;
    const language = localStorage.getItem('lang');
    setLanguage(language);
    let chatMsg = document.getElementById('chat_message_box') as HTMLInputElement;
    chatMsg.placeholder = '' + (translations as any)[language1]['chatPlaceholder'];

    this.initiateChatClient();
    if (localProps.receivedChatMessage != null && localProps.receivedChatMessage != '') {
      let message = localProps.receivedChatMessage;
      message = message.replace('<p>', '');
      message = message.replace('</p>', '');
      const formattedMessage = "<div class='received-message'>" + message + '</div>';
      this.setState({
        message: '',
        messages: this.state.messages + '<br>' + formattedMessage
      });
    }

    if (allMessages) {
      this.setState({
        message: '',
        messages: allMessages
      });
    }

    document.getElementById('chat_message_box')?.addEventListener('keydown', (e) => {
      if (e.key === 'Enter') {
        this.handleSendMessage();
      }
    });
  }
  componentDidUpdate() {
    allMessages = this.state.messages;
  }
  initiateChatClient = async () => {
    const chatClient = localProps.chatClient;

    //await chatClient.startRealtimeNotifications();
    const groupIdArr = localProps.groupId.split('/');
    const threadId = groupIdArr[5];
    console.log('initiateChatClient threadId = ' + threadId);
    chatClient.on('chatMessageReceived', (e: any) => {
      console.log('Notification chatMessageReceived!');
      if (e.sender.communicationUserId != localProps.userId) {
        this.renderReceivedMessage(e.message, e.sender.communicationUserId);
      } else {
        this.renderSentMessage(e.message, localProps.userId);
      }
    });
    this.chatThreadClient = await chatClient.getChatThreadClient(threadId);
  };

  state = {
    messages: '',
    message: ''
  };
  handleMessageChange = (event: any) => {
    this.setState({
      message: event.target.value,
      messages: this.state.messages
    });
  };

  handleSendMessage = async () => {
    this.chatThreadClient?.getProperties();
    let sendMessageRequest = { content: this.state.message };
    let sendMessageOptions = { senderDisplayName: localProps.displayName };
    try {
      if (this.state.message != '' && this.state.message != null) {
        await this.chatThreadClient?.sendMessage(sendMessageRequest, sendMessageOptions);
      }
    } catch (err) {
      alert(err);
      /* let error: string = JSON.stringify(err);
            if (error.includes("Forbbiden")) {
                this.initiateChatClient();
                await this.chatThreadClient.sendMessage(sendMessageRequest, sendMessageOptions);
            } */
    }
    this.setState({
      message: '',
      messages: this.state.messages
    });
  };

  renderReceivedMessage = async (message: string, user: string) => {
    message = message.replace('<p>', '');
    message = message.replace('</p>', '');
    const formattedMessage = "<div class='received-message'>" + message + '</div>';
    this.setState({
      message: '',
      messages: this.state.messages + formattedMessage
    });
    this.updateScroll();
  };

  renderSentMessage = async (message: string, user: string) => {
    if (message != '') {
      const formattedMessage = "<div class='sent-message'>" + message + '</div>';
      this.setState({
        message: '',
        messages: this.state.messages + formattedMessage
      });
    }
    this.updateScroll();
  };

  updateScroll() {
    let element = document.getElementById('messages-container') as HTMLElement;
    element.scrollTop = element.scrollHeight - 100;
  }
  submitFile = (event: any) => {
    const formData = new FormData();
    const selectedFile: File = event.target.files[0];
    const allowedFileExtensions = ['pdf', 'jpeg', 'png', 'jpg', 'doc', 'docx'];
    var ext = selectedFile.name.split('.')[1];

    if (!allowedFileExtensions.includes(ext)) {
      alert("مسموح بهذه الملفات فقط ['pdf','jpeg','png','jpg','doc','docx']");
      return false;
    } else if (selectedFile.size > 16777216) {
      alert('حجم الملف لا يزيد عن15 ميجا');
      return false;
    }
    const queryParams = new URLSearchParams(window.location.search);
    const meetingID: any = queryParams.get('meetingID');

    formData.append('file', selectedFile);
    formData.append('callId', meetingID);
    // const myHeaders = new Headers();
    // myHeaders.append("accept", "*/*");
    const requestOptions = {
      method: 'POST',
      // headers: myHeaders,
      body: formData
    };
    const url = appconfig.azure_reports_url + '/Attatchment/customer/upload'; //appconfig.file_upload_server+"/sbc/objectstore/api/uploadfile/";
    fetch(url, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        //alert(JSON.stringify(result))
        //this.saveFileURL(result.link);
        console.log('Share file success response:', result);
        /*  this.setState({
                    message: result.link
                }); */
        this.setState({
          message: 'تم مشاركة الملف بنجاح'
        });
        this.handleSendMessage();
        let uploadFileBtn = document.getElementById('uploadFileBtn') as HTMLInputElement;
        uploadFileBtn.value = '';
      })
      .catch((error) => {
        alert('Error:' + JSON.stringify(error));
        console.error('Error:', error);
      });
    return true;
  };

  saveFileURL = (fileurl: string) => {
    const queryParams = new URLSearchParams(window.location.search);
    const meetingID = queryParams.get('meetingID');
    const requestBody = {
      Attachments: [fileurl, fileurl]
    };

    let common = store.getState().common;
    const config = {
      headers: {
        api_token: common.token
      }
    };
    // alert(JSON.stringify(requestBody));
    //https://gosivvas-report-api-uat.azurewebsites.net/api/addattachment/06191951-be2f-43ed-a382-0b9095e8c2ad
    const url = appconfig.azure_reports_url + '/api/addattachment/' + meetingID;
    axios
      .post(url, requestBody, config)
      .then((response) => {
        // alert(JSON.stringify(response));
        console.log('saveFileUrl msg = ' + response);
      })
      .catch((error) => {
        alert(JSON.stringify(error));
      });
  };
  openFileAppload = () => {
    let uploadFileBtn = document.getElementById('uploadFileBtn') as HTMLInputElement;
    uploadFileBtn.click();
  };

  render() {
    if (localProps.callState === Constants.CONNECTED) {
      return (
        <div>
          <div dir="rtl" className="chat-container" style={{ backgroundColor: '#fff' }}>
            <div dir="rtl" className="chat-container-2">
              <div className="chat-popup">
                <div>
                  <div id="messages-container" dangerouslySetInnerHTML={{ __html: this.state.messages }}></div>
                </div>
                <div>
                  <form className="form-container">
                    <div className="chatRow">
                      <i className="bi bi-paperclip btn-link float-right" onClick={this.openFileAppload}></i>
                      <input
                        type="file"
                        name="file"
                        style={{ display: 'none' }}
                        id="uploadFileBtn"
                        onChange={this.submitFile}
                        className="filestyle"
                        data-classButton="btn btn-primary"
                        data-input="false"
                        data-classIcon="icon-plus"
                        data-buttonText="d"
                      />
                      <textarea
                        placeholder="اكتب هنا..."
                        onChange={this.handleMessageChange}
                        value={this.state.message}
                        name="chat_message_box"
                        id="chat_message_box"
                        required
                      ></textarea>
                      <button type="button" onClick={this.handleSendMessage} data-i18n="chat_btn1">
                        ارسال
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return <div>There is no active calls</div>;
    }
  }
}
