import React, { Component } from 'react';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.css';
import appconfig from "./../appconfig.json";
import { connect } from "react-redux";
import { State } from '../core/reducers';
import setLanguage from 'translation';

export interface WaitingProps {
    callState: string;
    otpToken: string;
    meetingId: string;
    meetingLink: string;
    serviceType: string;
}
 

class WaitingScreen extends Component {

    constructor(public props: WaitingProps) {
        super(props);
    }


   

    startChat = function () {
        window["startChat"]();
    }
    state = {
        waitingCount: ""
    }

    startWaitingTime = new Date();
   // queueLoadingCounter = 0;
    fetchWaitingCount = () => {
        if(!navigator.onLine){
            const status = "true";
            localStorage.setItem("offLineStatus", status);
        }
        if(navigator.onLine){
            if(localStorage.getItem("offLineStatus")  == "true"){
                localStorage.removeItem("offLineStatus");
                localStorage.removeItem("url");
                alert("there is a network issue. Please start again.");
                window.location.href = window.location.origin;
            }
        }
        //this.queueLoadingCounter++;
        this.setState({
            waitingCount: ""
        })
        const queryParams = new URLSearchParams(window.location.search);
        const meetingID = queryParams.get("meetingID");
        const serviceType = queryParams.get("serviceType");
        console.log('waiting screen > meeting id = ' + meetingID);
        console.log('waiting screen > serviceType = ' + serviceType);
        //https://endak-momrah-calling-bot.azurewebsites.net/api/customer/bb6c5a97-8963-4a31-91fe-2b6cdb8d66e6/waiting
        const url = appconfig.azure_reports_url + '/api/customer/' + meetingID + '/waiting/' + serviceType;
        console.log('waiting screen > waiting api url = ' + url);
        const config = {
            headers: { 
            //   Authorization: `Bearer ${this.props.otpToken}`,
              api_token: this.props.otpToken
             }
          };
        axios.get(url , config).then(response => {
           
            console.log("fetchWaitingCount : count = "+response.data.count);
           //alert(this.queueLoadingCounter);
           // if(response.data.count > 0 || this.queueLoadingCounter > 2){
            if(response.data.count > 0 ){
                this.setState({
                    waitingCount: response.data.count
                })
            }else{

                this.setState({
                    waitingCount: 0
                })
            }
        })
    }

    checkWaitingTimeIsExpired = () => {
        let currentTime = new Date();
        var timeDiffInSeconds = (currentTime.getTime() - this.startWaitingTime.getTime()) / 1000;
        if (timeDiffInSeconds > parseInt(appconfig.waiting_expiry_in_minutes) * 60) {
            const urlParams = new URLSearchParams(window.location.search);
            const meetingID = urlParams.get("meetingID");
            let isPublic = urlParams.get("isPublic");
            window.location.href = window.location.href.split('?')[0] + "waitingTimeExpired?meetingID=" + meetingID + "&isPublic=" + isPublic;
        }
    }

    fetchWaitingCountInterval = setInterval(this.fetchWaitingCount, 10000);
    checkWaitingTimeIsExpiredInterval = setInterval(this.checkWaitingTimeIsExpired, 30000);
    componentWillUnmount(): void {
        clearInterval(this.fetchWaitingCountInterval);
        clearInterval(this.checkWaitingTimeIsExpiredInterval);
    }


    componentDidMount() {
      this.fetchWaitingCount();
     // this.startCall();
      const language = localStorage.getItem("lang") ;
        setLanguage(language);
        //alert(language);
    }

    exitCall = () => {
        this.utility.endCall();
    }

    //startCall = () =>{
    //    const config = {
    //        headers: {
    //          api_token: this.props.otpToken
    //        }
    //      };
    //      const requestBody = {
    //        "Id": this.props.meetingId,
    //        "ServiceType": this.props.serviceType,
    //        "MeetingUrl": this.props.meetingLink,
    //      };
    //    const url = appconfig.azure_url + "/api/customer-meeting/start";
    //    axios.post(url, requestBody  , config).then(response => {
    //        console.log(JSON.stringify(response));
    //    })
    //}

    utility = {
        endCall: async (): Promise<void> => {
            const urlParams = new URLSearchParams(window.location.search);
            const meetingID = urlParams.get('meetingID');
            localStorage.removeItem("url");
            localStorage.removeItem("assignTo");
            const requestOptions = {
                method: 'GET',
                headers: {
                     'Content-Type': 'application/json' ,
                     'api_token': this.props.otpToken,
                    },
            }

            const url = appconfig.azure_reports_url + "/api/customer-meeting/end/" + meetingID;
            console.log('End Call URL >>' + url);
            const response = fetch(url, requestOptions);
            console.log('end call response = ' + response);
            let isPublic = urlParams.get("isPublic");
             window.location.href = window.location.href.split('?')[0] + "abnormalRating?meetingID=" + meetingID + "&isPublic=" + isPublic;

        }
    }

    queryParams = new URLSearchParams(window.location.search);
    isPublic = this.queryParams.get("isPublic");
    render() {
        let className = "";
        if(this.isPublic == "true"){
          className = "main-container";
        }else{
          className = "private-container";
        }
        return (
            <div id="main">
                <div className={className} id="content-container">
                   {/*  <div className='row'>
                        <span data-i18n="waiting_message_1">
                        موظفينا فى خدمة عملاء اخرين وسيتم تحويلك فى أسرع وقت ممكن
                        </span>
                        ,
                        <b data-i18n="waiting_message_2">الرجاء عدم تحديث أو اغلاق الصفحة</b>
                    </div> */}
              
                    <div className='svg2'  ></div>

                    {/*We appreciate your time waiting ,a customer service agent will assist you soon  ........ Number of Customers waiting: 2 customers*/}

                   
                   
                    <div id="queueDiv" style={{ textAlign:"center"}} >
                        <span style={{ fontSize: "20px" }} data-i18n="waitMsgL1">  نقدر لك انتظارك,  </span>
                        <br></br>
                        <span style={{ fontSize: "20px" }} data-i18n="waitMsgL2">   ممثل خدمة العملاء فى خدمة عملاء اخرين يرجى الانتظار ...  </span>
                        <br></br>
                        <span style={{ fontSize: "20px" }} data-i18n="waitMsgL3">أمامك فى الانتظار : </span>
                        
                        <span className="eng-num" style={{fontSize:"20px",color:"green"}}> {this.state.waitingCount}</span>
                        <span style={{ fontSize: "20px" }} data-i18n="waitMsgL4">  عملاء  </span>
                    </div>
                     
                        <br></br>
                    <div style={{textAlign:"center"}}>
                    <button onClick={this.exitCall} className='btn-exit' style={{ width: "7rem", height: "2rem", border: "0", backgroundColor: "rgb(215, 70, 84)", color: "white" }} ><span data-i18n="exit_btn">خروج</span></button>
                </div>
                {/* <div className='row'>
                    <div className='col-md-12' dir='rtl'>
                        <div className='custom-card custom-card-primary custom-card-sec'>
                            <div className='row'>
                                <div className='col-lg-2'>
                                    <div className='wall-end'>
                                        <img src='\GOSIOnlineStatic\img\icon_chat.png' width="50" height="50"></img>
                                        <h6>المحادثة الفورية</h6>
                                    </div>
                                </div>
                                <div className='col-lg-8' >
                                    <div className='align-items-center d-flex h-100 container'>مستعجل ودك نخدمك الان ! جرب المحادثة الفورية</div>
                                </div>
                                <div className='col-lg-2'>
                                    <div className='align-items-center d-flex h-100 justify-content-center'><button onClick={this.startChat} className="btn btn-success">بدء المحادثة</button></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
                </div>
            </div>
            
        );
    }

}

const mapStateToProps = (state: State, props: WaitingProps) => ({
    callState: state.calls.callState,
    otpToken: state.common.token,
    meetingId: state.common.meetingID,
    serviceType:state.common.serviceType,
    meetingLink: state.common.meetingLink,
});

const mapDispatchToProps = (dispatch: any, props: WaitingProps) => ({

});

const connector: any = connect(mapStateToProps, mapDispatchToProps);
export default connector(WaitingScreen);



