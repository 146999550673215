import * as React from 'react';
import { Stack, CommandButton } from '@fluentui/react';
 //import {ChatFeature} from './GroupCall';
import {
  MicIcon,
  MicOffIcon,
  CallVideoIcon,
  CallVideoOffIcon,
  CallEndIcon,
  CallControlPresentNewIcon,
  CallControlCloseTrayIcon
} from '@fluentui/react-icons-northstar';
import {
  controlButtonStyle,
  SmEndCallButton,
  endCallButtonStyle,
  endCallButtonTextStyle,
  mediaControlStyles,
  fullWidth,
  controlButtonDisabledStyle
} from './styles/MediaControls.styles';
import { ParticipantStream } from 'core/reducers';
import { CommandPanelTypes } from './CommandPanel';
import { Constants } from 'core/constants';


export interface MediaControlsProps {
  micActive: boolean;
  screenShareActive: boolean;
  activeScreenShareStream: ParticipantStream | undefined;
  cameraActive: boolean;
  cameraPermission: string;
  microphonePermission: string;
  localVideoRendererIsBusy: boolean;
  compressedMode: boolean;
  onMicChange(): void;
  onCameraChange(): void;
  onScreenShareChange(): void;
  onEndCallClick(): void;
  isLocalScreenShareSupportedInBrowser(): boolean;
  selectedPane: CommandPanelTypes;
  setSelectedPane: any;
  callState: string;
  receivedChatMessage:string;
}

export default (props: MediaControlsProps): JSX.Element => {
  const cameraActive = props.cameraPermission === 'Denied' ? false : props.cameraActive;
  const cameraDisabled = props.cameraPermission === 'Denied';
  const cameraOnClick = props.cameraPermission !== 'Denied' ? props.onCameraChange : undefined;
  const micActive = props.microphonePermission === 'Denied' ? false : props.micActive;
  const micDisabled = props.microphonePermission === 'Denied';
  const micOnClick = props.microphonePermission !== 'Denied' ? props.onMicChange : undefined;
  const screenShareDisabled = !!props.activeScreenShareStream;

 

  const showHideChatFeature = () =>{
    
    const toggleChat = (selectedPane: string, setSelectedPane: (pane: string) => void) => {
      return selectedPane !== CommandPanelTypes.Chat
        ? setSelectedPane(CommandPanelTypes.Chat)
        : setSelectedPane(CommandPanelTypes.None);
    };
  
    toggleChat(props.selectedPane, props.setSelectedPane);
 
  }


  if(props.callState === Constants.CONNECTED){

    //window["hideChatbot"]();
     
    if(props.receivedChatMessage != null && props.receivedChatMessage != ""){
       //alert("message received = '"+props.receivedChatMessage+"'");
        let chatIcon = document.getElementById('chat_feature_icon_div') as HTMLElement;
        let chatDiv = document.getElementById('messages-container') as HTMLElement;
        //alert(chatDiv)
        if(chatDiv == undefined || chatDiv == null){
          if(chatIcon) {
            chatIcon.click();
          }
        }
    }
  return (
    <div style={{textAlign:"left"}}>

{/*
   <div id="chat_feature_icon_div" >
      <img src='\GOSIOnlineStatic\img\icon_chat.png' onClick={showHideChatFeature}   width="40" height="40"></img>
    </div> 
    */}

 
     
 
 
    <Stack className={mediaControlStyles}  style={{textAlign:'left'}}>
    <CommandButton
        onClick={props.onEndCallClick}  
       className={props.compressedMode ? SmEndCallButton : endCallButtonStyle}
      >
        <div className={fullWidth}>
          <CallEndIcon size="medium" />
          {!props.compressedMode && <span className={endCallButtonTextStyle} data-i18n="exit_btn" >خروج</span>}
        </div>
      </CommandButton>

    <CommandButton
        onKeyDownCapture={(e) => {
          if (e.keyCode === 13 && props.localVideoRendererIsBusy) {
            e.preventDefault();
          }
        } }
       // disabled={cameraDisabled}
        onClick={showHideChatFeature}
        className={controlButtonStyle}
      >
        <div className={fullWidth}>
        <div id="chat_feature_icon_div" >
          <span className='flexContainer-225 ms-Button-flexContainer'   >
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chat-text-fill" viewBox="0 0 16 16">
              <path d="M16 8c0 3.866-3.582 7-8 7a9.06 9.06 0 0 1-2.347-.306c-.584.296-1.925.864-4.181 1.234-.2.032-.352-.176-.273-.362.354-.836.674-1.95.77-2.966C.744 11.37 0 9.76 0 8c0-3.866 3.582-7 8-7s8 3.134 8 7zM4.5 5a.5.5 0 0 0 0 1h7a.5.5 0 0 0 0-1h-7zm0 2.5a.5.5 0 0 0 0 1h7a.5.5 0 0 0 0-1h-7zm0 2.5a.5.5 0 0 0 0 1h4a.5.5 0 0 0 0-1h-4z"/>
            </svg>
          </span>
        </div>   
        </div>
      </CommandButton>


      <CommandButton
        onKeyDownCapture={(e) => {
          if (e.keyCode === 13 && props.localVideoRendererIsBusy) {
            e.preventDefault();
          }
        } }
        disabled={cameraDisabled}
        onClick={cameraOnClick}
        className={controlButtonStyle}
      >
        <div className={fullWidth}>
          {cameraActive ? <CallVideoIcon size="medium" /> : <CallVideoOffIcon size="medium" />}
        </div>
      </CommandButton>
       
          <CommandButton onClick={micOnClick} disabled={micDisabled} className={controlButtonStyle}>
            <div className={fullWidth}>{micActive ? <MicIcon size="medium" /> : <MicOffIcon size="medium" />}</div>
          </CommandButton>
       
      {props.isLocalScreenShareSupportedInBrowser() && (
        <CommandButton
          disabled={screenShareDisabled}
          onClick={props.onScreenShareChange}
          className={screenShareDisabled ? controlButtonDisabledStyle : controlButtonStyle}
        >
          <div className={fullWidth}>
            {props.screenShareActive ? (
              <CallControlCloseTrayIcon size="medium" />
            ) : (
              <CallControlPresentNewIcon size="medium" />
            )}
            {<span className={endCallButtonTextStyle}>Share Screen</span>}
          </div>
        </CommandButton>
      )}
    
   
    </Stack></div>
  );
}else {
  return (    
    <div>
    {/* <Stack className={mediaControlStyles}>
       <CommandButton
        onClick={props.onEndCallClick}  
        className={props.compressedMode ? controlButtonStyle : endCallButtonStyle}
        
      >
        <div className={fullWidth}>
          <CallEndIcon size="medium" />
          {!props.compressedMode && <span className={endCallButtonTextStyle}>خروج</span>}
        </div>
      </CommandButton>
    </Stack>*/}
    </div>
  )
}

};

