import { connect } from 'react-redux';
import GroupCall, { GroupCallProps } from '../components/GroupCall';
import { joinGroup, setMicrophone } from '../core/sideEffects';
import { initCallAgent, initCallClient} from '../core/sideEffects';
import { setLocalVideoStream } from '../core/actions/streams';
import { setVideoDeviceInfo, setAudioDeviceInfo } from '../core/actions/devices';
import {
    AudioDeviceInfo,
    VideoDeviceInfo,
    LocalVideoStream
} from '@azure/communication-calling';
import { State } from '../core/reducers';
import { setGroup } from 'core/actions/calls';
import { setMic } from '../core/actions/controls';

const mapStateToProps = (state: State, props: GroupCallProps) => ({
    userId: state.sdk.userId,
    userToken: state.sdk.userToken,
    displayName:state.sdk.displayName,
    callAgent: state.calls.callAgent,
    chatClient: state.calls.chatClient,
    deviceManager: state.devices.deviceManager,
    group: state.calls.group,
    screenWidth: props.screenWidth,
    call: state.calls.call,
    shareScreen: state.controls.shareScreen,
    mic: state.controls.mic,
    groupCallEndReason: state.calls.groupCallEndReason,
    isGroup: () => state.calls.call && state.calls.call.direction !== 'Incoming' && !!state.calls.group,
    joinGroup: async () => {
        state.calls.callAgent &&
            
            await joinGroup(
                state.calls.callAgent,
                {
                    // groupId: state.calls.group
                    meetingLink: state.calls.group
                },
                {
                    videoOptions: { localVideoStreams: state.streams.localVideoStream ? [state.streams.localVideoStream] : undefined },
                    //audioOptions: { muted: !state.controls.mic }
                    audioOptions: { muted: false }
                }
            );
    },
    remoteParticipants: state.calls.remoteParticipants,
    callState: state.calls.callState,
    localVideo: state.controls.localVideo,
    localVideoStream: state.streams.localVideoStream,
    screenShareStreams: state.streams.screenShareStreams,
    videoDeviceInfo: state.devices.videoDeviceInfo,
    audioDeviceInfo: state.devices.audioDeviceInfo,
    videoDeviceList: state.devices.videoDeviceList,
    audioDeviceList: state.devices.audioDeviceList,
    cameraPermission: state.devices.cameraPermission,
    microphonePermission: state.devices.microphonePermission,
    receivedChatMessage: state.calls.chatMessage
});

const mapDispatchToProps = (dispatch: any , props: GroupCallProps) => ({
    mute: () => dispatch(setMicrophone(false)),
    setMic: (mic: boolean) => dispatch(setMic(mic)),
    setAudioDeviceInfo: (deviceInfo: AudioDeviceInfo) => {
        dispatch(setAudioDeviceInfo(deviceInfo));
    },
    setVideoDeviceInfo: (deviceInfo: VideoDeviceInfo) => {
        dispatch(setVideoDeviceInfo(deviceInfo));
    },
    setLocalVideoStream: (localVideoStream: LocalVideoStream) => dispatch(setLocalVideoStream(localVideoStream)),
    setupCallClient: (unsupportedStateHandler: () => void) =>
    dispatch(initCallClient( unsupportedStateHandler)),
    setupCallAgent: (displayName: string) =>
    dispatch(initCallAgent(displayName, props.endCallHandler)),
    setGroup: (groupId: string) => dispatch(setGroup(groupId)),
    
});

const connector: any = connect(mapStateToProps, mapDispatchToProps);
export default connector(GroupCall);
