
const SET_MEETING_ID = 'SET_MEETING_ID';
const SET_MEETING_LINK = 'SET_MEETING_LINK'
const SET_SERVICE_TYPE = 'SET_SERVICE_TYPE'
const SET_SUB_SERVICE_TYPE = 'SET_SUB_SERVICE_TYPE'
const SET_FULL_NAME = 'SET_FULL_NAME'
const SET_COMMERCIAL_NUMBER = 'SET_COMMERCIAL_NUMBER'
const SET_NIN = 'SET_NIN'
const SET_MOBILE = 'SET_MOBILE'
const SET_MEETING_START_TIME = 'SET_MEETING_START_TIME'
const SET_TOKEN = "SET_TOKEN";


interface SetMeetingIDAction {
    type: typeof SET_MEETING_ID;
    meetingID: string;
}

interface SetMeetingLinkAction {
    type: typeof SET_MEETING_LINK;
    meetingLink: string;
}

interface SetServiceTypeAction {
    type: typeof SET_SERVICE_TYPE;
    serviceType: string;
}

interface SetSubServiceTypeAction {
  type: typeof SET_SUB_SERVICE_TYPE;
  subServiceType: string;
}

interface SetFullNameAction {
    type: typeof SET_FULL_NAME;
    fullName: string;
}
interface SetCommercialNumberAction {
  type: typeof SET_COMMERCIAL_NUMBER;
  commercialNumber: string;
}
interface SetNINAction {
    type: typeof SET_NIN;
    nin: string;
}
interface SetMobileAction {
    type: typeof SET_MOBILE
    mobile: string;
}

interface SetMeetingStartTimeAction {
  type: typeof SET_MEETING_START_TIME
  meetingStartTime: string;
}

interface SetTokenAction {
  type: typeof SET_TOKEN
  token: string;
}

export const setMeetingID = (meetingID: string): SetMeetingIDAction => {
    return {
      type: SET_MEETING_ID,
      meetingID
    };
  };

  export const setMeetingLink = (meetingLink: string): SetMeetingLinkAction => {
    return {
      type: SET_MEETING_LINK,
      meetingLink
    };
  };

  export const setServiceType = (serviceType: string): SetServiceTypeAction => {
    return {
      type: SET_SERVICE_TYPE,
      serviceType
    };
  };

  export const setSubServiceType = (subServiceType: string): SetSubServiceTypeAction => {
    return {
      type: SET_SUB_SERVICE_TYPE,
      subServiceType
    };
  };

  export const setFullName = (fullName: string): SetFullNameAction => {
    return {
      type: SET_FULL_NAME,
      fullName
    };
  };

  export const setCommercialNumber = (commercialNumber: string): SetCommercialNumberAction => {
    return {
      type: SET_COMMERCIAL_NUMBER,
      commercialNumber
    };
  };
  
  export const setNin = (nin: string): SetNINAction => {
    return {
      type: SET_NIN,
      nin
    };
  };

  export const setMobile = (mobile: string): SetMobileAction => {
    return {
      type: SET_MOBILE,
      mobile
    };
  };

  export const setMeetingStartTime = (meetingStartTime: string): SetMeetingStartTimeAction => {
    return {
      type: SET_MEETING_START_TIME,
      meetingStartTime
    };
  };

  export const setToken = (token: string): SetTokenAction => {
    return {
      type: SET_TOKEN,
      token
    };
  };

export {
    SET_MEETING_ID,
    SET_MEETING_LINK,
    SET_SERVICE_TYPE,
    SET_SUB_SERVICE_TYPE,
    SET_FULL_NAME,
    SET_COMMERCIAL_NUMBER,
    SET_NIN,
    SET_MOBILE,
    SET_MEETING_START_TIME,
    SET_TOKEN
};


export type CommonTypes = SetMeetingIDAction | SetMeetingLinkAction | SetServiceTypeAction | SetFullNameAction | SetCommercialNumberAction | SetNINAction | SetMobileAction | SetMeetingStartTimeAction | SetTokenAction | SetSubServiceTypeAction;

 