// © Microsoft Corporation. All rights reserved.
import React, { useState, useEffect} from 'react';
import GroupCall from './containers/GroupCall';
import { createStore, applyMiddleware } from 'redux';
import { Provider } from 'react-redux';
import { reducer } from './core/reducers';
import thunk from 'redux-thunk';
import PublicConfigurationScreen from './containers/PublicConfiguration';
import { loadTheme, initializeIcons } from '@fluentui/react';
import { utils } from './Utils/Utils';
import { CallEndReason } from '@azure/communication-calling';
import AfterWorkingHours from 'components/AfterWorkingHours';
import UnSupportedBrowserScreen from 'components/UnSupportedBrowseScreen';
import InputForm from 'components/InputForm';
import {persistStore,persistReducer} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import {PersistGate} from 'redux-persist/integration/react'
//import appconfig from "./appconfig.json";
 



const sdkVersion = require('../package.json').dependencies['@azure/communication-calling'];
const lastUpdated = `Last Updated ${utils.getBuildTime()} with @azure/communication-calling:${sdkVersion}`;

loadTheme({});
initializeIcons();


const persistConfig = {
  key : 'persist-key',
  storage
  }
  
const persistedReducer = persistReducer(persistConfig,reducer)

//const store = createStore(reducer, applyMiddleware(thunk));
export const store = createStore(persistedReducer, applyMiddleware(thunk));
export const persistedStore = persistStore(store)
 

const App = () => {
  const [page, setPage] = useState('home');
  const [callEndReason, setCallEndReason] = useState<CallEndReason | undefined>();
  const [meetingLink, setMeetingLink] = useState('');
  const [screenWidth, setScreenWidth] = useState(0);
  const[workingHours , setWorkingHours] = useState("false");
 
  useEffect( () => {
    inWorkingHours();
    const url = localStorage.getItem("url");
    if(url !== null){
      const urlParams = new URLSearchParams(url);
      const link = urlParams.get('meetingLink');
      if (link) setMeetingLink(link);
    }

    const setWindowWidth = () => {
      const width = typeof window !== 'undefined' ? window.innerWidth : 0;
      setScreenWidth(width);
    };

    // const handleTabClose = (event:any) => {
    //   event.preventDefault();
    //   utils.registerEvent("user disconnected");
    // };
    //window.addEventListener('beforeunload', handleTabClose);
    setWindowWidth();
    window.addEventListener('resize', setWindowWidth);
   
    
    return () => {
      //window.removeEventListener('beforeunload', handleTabClose);
      window.removeEventListener('resize', setWindowWidth);
    };
  }, []);

   const getGroupIdFromUrl = () => {
    const url = localStorage.getItem("url");
    var link:any = "";
    if(url !== null){
      const urlParams = new URLSearchParams(url);
      link = urlParams.get('meetingLink');
    }
    if (link) setMeetingLink(link);
    return link;
  };
 
 
  const isConfigPage = () =>{
    const urlParams = new URLSearchParams(window.location.search);
    const config = urlParams.get('config');
    if(config == "true"){
      return true;
    }else{
      return false;
    }
  }

  const getGroupId = () => {
    return meetingLink;
  };

  const inWorkingHours = () =>{
  
  // let url = appconfig.azure_reports_url +"/api/customer-meeting/isWorkingHours/sbc-others";
  // fetch(url).then(response =>{
  //     return response.json();
  //   }) .then(json => {
  //      //setWorkingHours(json.message);
  //     }) 
      setWorkingHours("true");
   

  } 

  const getContent =  () => { 
   let isSupportedBrowser =  window["isSupportedBrowser"](); 
    if (page === 'home') {
      if(workingHours == "true" ){ 
         if(isSupportedBrowser == false){
          return <UnSupportedBrowserScreen />
         } else {
        if(isConfigPage()){
          return (
            <PublicConfigurationScreen
              startCallHandler={() => setPage('call')}
              unsupportedStateHandler={() => setPage('unsupported')}
              callEndedHandler={(errorMsg: CallEndReason) => {
                setCallEndReason(errorMsg);
                setPage('error');
              }}
               groupId={getGroupId()}
              screenWidth={screenWidth}
            />
          );
        }else{
          return <InputForm />   
        }
      }
      }else{
        return <AfterWorkingHours />
      }
      
  
    } else if (page === 'call') {
       
      if(getGroupId() != ""){
        
        return <GroupCall endCallHandler={() => setPage('endCall')} groupId={getGroupId()} screenWidth={screenWidth} />;
      }else {
        return  "";
      }
    } else if (page === 'endCall') {
      const queryParams = new URLSearchParams(window.location.search);
       const meetingID = queryParams.get("meetingID");
       let isPublic = queryParams.get("isPublic");
       window.location.href = window.location.href.split('?')[0]+"rating?meetingID="+meetingID+ "&isPublic="+isPublic;  
       return;
     
    } else if (page === 'unsupported') {
      window.document.title = 'Unsupported browser';
      return (
        <>
          <a href="https://docs.microsoft.com/en-us/azure/communication-services/concepts/voice-video-calling/calling-sdk-features#calling-client-library-browser-support">
            Learn more
          </a>
          &nbsp;about browsers and platforms supported by the web calling sdk
        </>
      );
    } else if (page === 'error') {
      window.document.title = 'Call Ended';
      return (
        <div>
          <div>{`The call has ended with this error code (Code: ${callEndReason?.code} Subcode: ${callEndReason?.subCode})`}</div>

          <div>
            <a href="https://docs.microsoft.com/en-us/azure/communication-services/concepts/troubleshooting-info?tabs=csharp%2Cjavascript%2Cdotnet">
              Learn more
            </a>
            &nbsp;about why this Azure Communication Services call has ended.
          </div>
        </div>
      );
    } else {
      return <></>;
    }
  };

  if (page === 'home') {
    if( getGroupIdFromUrl()) {
      setPage('call');
    }
  }
  
 /* if (page === 'home' && isConfigPage()) {
    
    setPage('configuration');
  }*/
 //alert(page);
  //return <Provider store={store}>{getContent()}</Provider>;
  return <Provider store={store}><PersistGate persistor={persistedStore}>{getContent()}</PersistGate></Provider>;
};

window.setTimeout(() => {
  try {
    console.log(`Azure Communication Services sample group calling app: ${lastUpdated}`);
  } catch (e) {}
}, 0);

export default App;
