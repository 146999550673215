import React, { useEffect } from 'react';
import axios from 'axios';
import appconfig from "../appconfig.json";
import { Stack, Spinner, PrimaryButton } from '@fluentui/react';
import LocalPreview from './LocalPreview';
import LocalSettings from './LocalSettings';
import '../welcome.css';
import {
  VideoDeviceInfo,
  AudioDeviceInfo,
  LocalVideoStream,
  DeviceManager,
  CallAgent,
  CallEndReason
} from '@azure/communication-calling';
import {
  configurationStackTokens,
  buttonStyle,
  localSettingsContainerStyle,
  mainContainerStyle,
  fullScreenStyle,
  verticalStackStyle
} from './styles/Configuration.styles';

import 'bootstrap/dist/css/bootstrap.css';
import setLanguage from 'translation';

export interface PublicConfigurationScreenProps {
  userId: string;
  groupId: string;
  callAgent: CallAgent;
  deviceManager: DeviceManager;
  setupCallClient(unsupportedStateHandler: () => void): void;
  setupCallAgent(displayName: string): void;
  setGroup(groupId: string): void;
  startCallHandler(): void;
  unsupportedStateHandler: () => void;
  callEndedHandler: (reason: CallEndReason) => void;
  videoDeviceList: VideoDeviceInfo[];
  audioDeviceList: AudioDeviceInfo[];
  setVideoDeviceInfo(device: VideoDeviceInfo): void;
  setAudioDeviceInfo(device: AudioDeviceInfo): void;
  mic: boolean;
  setMic(mic: boolean): void;
  setLocalVideoStream(stream: LocalVideoStream | undefined): void;
  localVideoRendererIsBusy: boolean;
  videoDeviceInfo: VideoDeviceInfo;
  audioDeviceInfo: AudioDeviceInfo;
  localVideoStream: LocalVideoStream;
  screenWidth: number;
  meetingID: string;
  meetingLink: string;
  fullName: string;
  commercialNumber: string;
  nin: string;
  meetingStartTime: string;
  otpToken: string;
  mobile: string;
  serviceType: string;
  subServiceType: string;
  setMeetingID(meetingID: string): void;
  setMeetingLink(meetingLink: string): void;
  setMeetingStartTime(meetingStartTime: string): void;
}
let callStarted: boolean = false;
const loadDevices = function () {
  navigator.mediaDevices.enumerateDevices().then(function (devices) {
    devices.forEach(function (device) {
      // console.log(device.kind + ": " + device.label +" id = " + device.deviceId);
      const deviceType = device.kind;
      if (device.deviceId == "default" || device.deviceId == "communications") return;
      if (deviceType == 'audioinput') {
        const option = document.createElement("option");
        option.text = device.label;
        option.value = device.deviceId;
        document.getElementById('micList')?.appendChild(option);
      } else if (deviceType == 'audiooutput') {
        const option = document.createElement("option");
        option.text = device.label;
        option.value = device.deviceId;
        document.getElementById('speakerList')?.appendChild(option);
      }
      else if (deviceType == 'videoinput') {
        const option = document.createElement("option");
        option.text = device.label;
        option.value = device.deviceId;
        document.getElementById('cameraList')?.appendChild(option);
      }
    });
  })


}




const checkPermissions = () => {
  (navigator as any).permissions.query({ name: 'camera' })
    .then((permission:any) => {
      console.log("camera state", permission.state)
      if (permission.state != "granted") {
        const ele = document.getElementById('cameraState')
        if (ele) {
          ele.style.display = '';
        }
      } else {
        const ele = document.getElementById('cameraList')
        if (ele) {
          ele.style.display = '';
        }
      }
    });

  (navigator as any).permissions.query({ name: 'microphone' })
    .then((permission:any) => {
      console.log("microphone state", permission);
      if (permission.state != "granted") {
        const ele = document.getElementById('microphoneState');
        if (ele) {
          ele.style.display = '';
        }
      } else {
        const ele = document.getElementById('micList');
        const eleSpeaker = document.getElementById('speakerListDiv');

        if (ele) {
          ele.style.display = '';
        }

        if (eleSpeaker) {
          eleSpeaker.style.display = '';
        }
      }
    });
}

const requestPermission = (media: string) => {

  if (media == "microphone") {

    navigator.mediaDevices.getUserMedia({ audio: true, video: false })
      .then(function (stream) {
        window.location.reload();
      });
  }
  if (media == "camera") {

    navigator.mediaDevices.getUserMedia({ audio: false, video: true })
      .then(function (stream) {
        window.location.reload();
      });
  }
  loadDevices();
}



export default (props: PublicConfigurationScreenProps): JSX.Element => {
  useEffect(() => {
    const language = localStorage.getItem("lang") ;
    setLanguage(language);
   
    let browser = (window as any)["getBrowser"]();
    if (browser == "Safari") {
      let micRow = document.getElementById('micRow') as HTMLElement;
      let cameraRow = document.getElementById('cameraRow') as HTMLElement;
      micRow.style.display = 'none';
      cameraRow.style.display = 'none';
    }else{
      loadDevices();
      checkPermissions();
    }
  }, []);

  const spinnerLabel = 'Initializing call client...';
  const buttonText = 'بدء الاتصال';
  const { setupCallClient, unsupportedStateHandler, setMeetingID, setMeetingLink, setMeetingStartTime } = props;

  useEffect(() => {
    setupCallClient(unsupportedStateHandler);
  }, [setupCallClient, unsupportedStateHandler]);

  const initiateNewCall = (fullName: string | null, commercialNumber: string | null, serviceType: string | null, mobile: string | null, nin: string | null, isPublic: string | null, investorData: string | null,lng:string | null) => {
    let browser = (window as any)["getBrowser"]();
    let mic = document.getElementById('micList') as HTMLSelectElement;
    if (mic.value == "" && browser != "Safari") {
      alert("حتى نتمكن من خدمتك الرجاء تفعيل الكاميرا والميكروفون");
      return;
    }
      const commercialRegId = localStorage.getItem("commercialNumber"); 
      const emailAddress = localStorage.getItem("emailAddress");
      const userType = localStorage.getItem("userType");
      //const serviceLanguage = localStorage.getItem("serviceLanguage");
      const serviceEntity = localStorage.getItem("serviceEntity");
      
      const parsedInvestorData = typeof investorData == "string" && JSON.parse(investorData)
     const requestBody = {
      "fullName": fullName,
      "serviceType": serviceType,
      "commercialRegId":commercialRegId,
      "emailAddress":emailAddress,
      "mobileNumber": mobile,
      "nin": nin,
      "userType": userType,
      "serviceLanguage": lng,
      "serviceEntity": serviceEntity,
      "gender":parsedInvestorData?.gender,
      "dob":parsedInvestorData?.dob,
      "nationality":parsedInvestorData?.nationality,
      "language":parsedInvestorData?.language,
    };

   
		const config = {
			headers: {
        Authorization: `Bearer ${props.otpToken}`,
        api_token: props.otpToken
			}
		};

    console.log("initiateNewCall >> config = " +config)

    const url = appconfig.azure_url + "/api/customer-meeting";
    axios.post(url, requestBody, config).then(response => {
      console.log(response);
      console.log(response.status);
      
      if (response.status == 401 || response.status == 403) {
          alert("invalid token");
      } else{
        let meetingID: string = response.data.id;
        let meetingLink: string = response.data.meetingLink;
        
        setMeetingID(meetingID);
        setMeetingLink(meetingLink);
        var time = new Date();
        setMeetingStartTime(time.getTime().toString());
        //meetingLink = meetingLink.replace("https://sbc-endak-frontend-test.azurewebsites.net", "http://localhost:3001");
        const urlParams = new URLSearchParams(window.location.search);
        const client = urlParams.get('client');
        localStorage.removeItem("commercialNumber");

        const link = meetingLink + "&meetingID=" + response.data.id + "&fullname=" + fullName + "&isPublic=" + isPublic + "&serviceType=" + serviceType + "&client=" + client;
        var hostURL = new URL(window.location.href);
        const baseURL =  hostURL.origin +"/";
        const actualLink = link.replace(baseURL,"");
        localStorage.setItem("url",actualLink);
        
        window.location.href ="?meetingLink=TeamsMeeting&meetingID=" + response.data.id + "&fullname=" + fullName + "&isPublic=" + isPublic + "&serviceType=" + serviceType + "&client=" + client;
    }
    }).catch(error => {
      //alert("Please login to Balady Portal");
      if (error.response.status == 400) {
          alert(error.response.data.message);
          window.location.href = window.location.origin;
      }

    })

  }




  return (
    <div className="content-container"  id="content-container">
      <div className='row'  >

        <div className='col-md-6'>
        <div className='row'>
              <div className='t2' data-i18n="our_branch">فرعنا الالكتروني <span className='t1' data-i18n="application_name">عندك</span></div>
          </div>
          <div className='row'>
              <span className='p1 p2' data-i18n="message">تتيح هذه الخدمة امكانية عقد <b>زيارة افتراضية </b>والتواصل مع موظف خدمة العملاء عبر الاتصال المرئى بكل سهولة ويسر</span>
          </div>
          <br /><br />
          <div className='row'><span className='t2' data-i18n="recordBold">ملاحظة:</span>
          </div>
          <div className='row'>
              <span className='p1 p2' data-i18n="recordMessage"> اتباعًا لنظام الاتصالات وتقنية المعلومات الصادر بقرار مجلس الوزراء رقم (592) بتاريخ 1443/11/1هـ ولائحته التنفيذية اتجاه حماية البيانات الشخصية للمستخدمين في قطاع الاتصالات والفضاء والتقنية؛ سنقوم بتسجيل المكالمات الهاتفية لأغراض تحسين جودة الخدمات المقدمة. هذه التسجيلات محمية وتستخدم فقط لتدريب موظفينا وضمان تقديم خدمة متميزة، ونضمن الحفاظ على سرية وخصوصية المعلومات الواردة في هذه المكالمات وفقًا للقوانين والأنظمة المتبعة</span>

          </div>
        </div>

        <div className='col-md-6'>
          <div className='leftSide'>
            <div className='row'>
              <div className='t1' data-i18n="for_better_service">لخدمتك بشكل أفضل  </div>
            </div>
            <br></br>
            <div className="row">
              <span className='p1' data-i18n="activate_mic_camera"> يرجى تفعيل الكامير والميكروفون</span>
            </div>
            <br></br>
            <div className="row" id="speakerListRow_01" style={{ display: "none" }}  data-i18n="mic"><span>جهاز الصوت</span></div>
            <div className="row" id="speakerListRow_02" style={{ display: "none" }}>

              <select id="speakerList" className="select-form"></select>

            </div>

            <div className="row"  id="micRow"><span data-i18n="mic">ميكروفون</span></div>
            <div className="row" >

              <select style={{ display: "none" }} id="micList" className="select-form"></select>
              <label id="microphoneState" style={{ display: 'none' }} className="switch"><input type="checkbox" onClick={event => requestPermission('microphone')} /><span className="slider round"></span></label>
            </div>
            <br />
            <div className="row" id="cameraRow"><span data-i18n="camera">الكاميرا</span></div>
            <div className="row" >

              <select style={{ display: "none" }} id="cameraList" className="select-form"></select>
              <label id="cameraState" style={{ display: 'none' }} className="switch"><input type="checkbox" onClick={event => requestPermission('camera')} /><span className="slider round"></span></label>
            </div>
            <br />
            <div className='row' style={{ textAlign: "center" }} >
              <div dir='rtl' id="xxx" style={{ width: "100%", textAlign: "center" }}>
                <Stack className={mainContainerStyle} style={{ textAlign: "center" }} horizontalAlign="center" verticalAlign="center">
                  <br />
                  {props.deviceManager ? (

                    <Stack
                      className={props.screenWidth > 750 ? fullScreenStyle : verticalStackStyle}
                      style={{ textAlign: "center" }}
                      horizontal={props.screenWidth > 750}
                      horizontalAlign="center"
                      verticalAlign="center"
                      tokens={props.screenWidth > 750 ? configurationStackTokens : undefined}
                    >

                      <LocalPreview
                        mic={props.mic}
                        setMic={props.setMic}
                        setLocalVideoStream={props.setLocalVideoStream}
                        videoDeviceInfo={props.videoDeviceInfo}
                        audioDeviceInfo={props.audioDeviceInfo}
                        localVideoStream={props.localVideoStream}
                        videoDeviceList={props.videoDeviceList}
                        audioDeviceList={props.audioDeviceList}
                      />

                      <Stack className={localSettingsContainerStyle}>


                        <LocalSettings
                          videoDeviceList={props.videoDeviceList}
                          audioDeviceList={props.audioDeviceList}
                          audioDeviceInfo={props.audioDeviceInfo}
                          videoDeviceInfo={props.videoDeviceInfo}
                          setVideoDeviceInfo={props.setVideoDeviceInfo}
                          setAudioDeviceInfo={props.setAudioDeviceInfo}
                          deviceManager={props.deviceManager}
                        />



                      </Stack>

                    </Stack>
                  ) : (
                    <Spinner label={spinnerLabel} ariaLive="assertive" labelPosition="top" />
                  )}
                </Stack>
              </div>
            </div>
 
            <div className='row'>

              <div dir='rtl' className='col-md-12 text-center'  >
                <PrimaryButton
                  id="startCallBtn" 
                  data-i18n="start_call_btn"
                  className={buttonStyle}
                  // style={{ backgroundColor: "#006937" }}
                  style={{ backgroundColor: "#046F6D" }}

                  onClick={async () => {
                    if (callStarted) return;
                    callStarted = true;
                    let callBtn = document.getElementById("startCallBtn") as HTMLButtonElement;
                    callBtn?.setAttribute("disabled", "disabled");
                    callBtn.style.backgroundColor = "#CCCCCC";

                    const queryParams = new URLSearchParams(window.location.search)
                   // let fullName = queryParams.get("fullname");
                   // let serviceType = queryParams.get("serviceType");
                    //let mobile = queryParams.get("mobile");
                    // let nin = queryParams.get("nin");
                    let isPublic = queryParams.get("isPublic");
                    let investorData = queryParams.get("investorData");
                    let lng = queryParams.get("lng");
                   /*  if (serviceType == null) {
                      serviceType = "gosi-establishment";
                    } */
                    //serviceType = "gosi-public";
                    initiateNewCall(props.fullName, props.commercialNumber, props.serviceType, props.mobile, props.nin, isPublic,investorData,lng);


                  }}
                >
                  {buttonText}
                </PrimaryButton>
              </div>

            </div>

          </div>
        </div>


      </div>



    </div>

  );


}




