import React, { Component } from 'react'
import 'bootstrap/dist/css/bootstrap.css';
import appconfig from "./../appconfig.json";
import axios from 'axios';

class AfterWorkingHours extends Component {
    /*openCallMe = () =>{
        var url = "http://www.google.com/";
        window.location.href = url;
    }
    
    openContactUs = () =>{
        var url = "http://www.google.com/";
        window.location.href = url;
    }*/

    componentDidMount(): void {
        this.loadWorkingHoursMessage();
    }
    loadWorkingHoursMessage = () => {
        let admin_messageAR = document.getElementById("admin_message") as HTMLElement;
        let admin_messageEN = document.getElementById("admin_messageEN") as HTMLElement;
        let default_message = document.getElementById("default_message") as HTMLElement;
        let url = appconfig.azure_reports_url + "/api/workinghrs";
        let lang = localStorage.getItem("lang");
        axios.get(url).then(response => {
            if (response.data.OffHourMessage != "") {
                default_message.style.display = "none";
                admin_messageEN.innerHTML = response.data.OffHourMessage[0];
                admin_messageAR.innerHTML = response.data.OffHourMessage[1];
                if(lang == "en"){
                    admin_messageEN.style.display = "";
                    admin_messageAR.style.display = "none";
                }else{
                    admin_messageAR.style.display = "";
                    admin_messageEN.style.display = "none";
                }
            } else {
                admin_messageAR.style.display = "none";
                admin_messageEN.style.display = "none";
                default_message.style.display = "";
            }
        })
    }

    render() {

        let isPublic = "true";

        if (isPublic == "true") {
            return (

                <div className='content-container' id="content_container"  >
                    {/* <div className='row'>
                        <div className='col-md-12 t2' data-i18n="our_branch">الأتصال المرئي  </div>
                    </div>
                    <div className='row'>
                        <span className='col-md-12 p1 p2'  data-i18n="message">تتيح هذه الخدمة امكانية عقد <b>زيارة افتراضية </b>والتواصل مع موظف خدمة العملاء عبر الاتصال المرئى بكل سهولة ويسر</span>
                    </div>
                    <hr></hr> */}
                    <div className='row'  >
                            <div className='col-md-12 text-center'  id="admin_message" style={{ display: "none" }}> </div>
                            <div className='col-md-12 text-center'  id="admin_messageEN" style={{ display: "none" }}> </div>
                    </div>

                    <div className='row'   id="default_message">
                        <div className='col-md-12' style={{ textAlign: "right" }}>
                            <div className='paragraph' style={{ textAlign: "right" }}>
                                عملائنا الأعزاء نعتذر منكم الخدمة غير متوفرة حاليا وتسعدنا خدمتكم خلال أوقات العمل
                                <dl>
                                    <li>
                                        من الاحد الى الخميس بين الساعة 08:00 صباحاً الى الساعة 06:00 مساءً
                                    </li>

                                    <li>
                                        الجمعة بين الساعة 02:00 مساءً الى الساعة 06:00 مساءً
                                    </li>

                                    <li>
                                        السبت بين الساعة 09:00 صباحاً الى الساعة 02:00 مساءً
                                    </li>
                                </dl>

                            </div>
                        </div>
                    </div>

                </div>

            )
        } else {
            return <></>
        }

    }
}

export default AfterWorkingHours;
