import { Reducer } from 'redux';
import {
  SET_MEETING_ID,
  SET_MEETING_LINK,
  SET_SERVICE_TYPE,
  SET_SUB_SERVICE_TYPE,
  SET_FULL_NAME,
  SET_COMMERCIAL_NUMBER,
  SET_NIN,
  SET_MOBILE,
  SET_TOKEN,
  SET_MEETING_START_TIME,
  CommonTypes
} from '../actions/common';

export interface CommonState {
  meetingID?: string;
  meetingLink?: string;
  serviceType?: string;
  subServiceType?: string;
  fullName?: string;
  commercialNumber?: string;
  nin?: string;
  mobile?: string;
  meetingStartTime?: string;
  token?: string;
}

const initialState: CommonState = {
  meetingID: undefined,
  meetingLink: undefined,
  serviceType: undefined,
  subServiceType: undefined,
  fullName: undefined,
  commercialNumber: undefined,
  nin: undefined,
  mobile: undefined,
  meetingStartTime: undefined,
  token: undefined
};



export const commonReducer: Reducer<CommonState, CommonTypes> = (state = initialState, action: CommonTypes): CommonState => {
  switch (action.type) {
    case SET_MEETING_ID:
      return { ...state, meetingID: action.meetingID };
    case SET_MEETING_LINK:
      return { ...state, meetingLink: action.meetingLink };
    case SET_SERVICE_TYPE:
      return { ...state, serviceType: action.serviceType };
    case SET_SUB_SERVICE_TYPE:
        return { ...state, subServiceType: action.subServiceType };
    case SET_FULL_NAME:
      return { ...state, fullName: action.fullName };
    case SET_COMMERCIAL_NUMBER:
      return { ...state, commercialNumber: action.commercialNumber };
    case SET_NIN:
      return { ...state, nin: action.nin };
    case SET_MOBILE:
      return { ...state, mobile: action.mobile };
    case SET_MEETING_START_TIME:
      return { ...state, meetingStartTime: action.meetingStartTime };
    case SET_TOKEN:
      return { ...state, token: action.token };
    default:
      return state;
  }
};
